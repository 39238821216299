import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { IAppsNames } from 'store/autosaas/autosaasReducer';
import { fetchAutoSassDesign } from 'store/autosaas/autosaasActions';
import { DispatchType, RootState } from 'store/rootReducer';
import { graphQlCall } from 'graphql/utils';
import { IPrice, IProduct } from 'Components/AutoSaas/constants';
import queries from 'graphql/queries';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import Applications from './Applications/Applications';
import PlanDetails from './PlanDetails/PlanDetails';
import Tabs from 'Components/Templates/Tabs/Tabs';

import styles from './AddProductPopup.module.scss';

interface IProps {
  item?: { index: number; item: IPrice };
  isVisible: boolean;
  onClose: () => void;
  hasProduct?: boolean;
  onUpdateData: (item: IProduct) => void;
  autoSassAppsNames?: IAppsNames[];
  fetchAutoSassDesign: () => void;
}

const AddProductPopup = ({
  item,
  isVisible,
  onClose,
  hasProduct,
  onUpdateData,
  autoSassAppsNames,
  fetchAutoSassDesign,
}: IProps) => {
  const [planData, setPlanData] = useState<IPrice | null>(initializePlanData());
  const [loading, setLoading] = useState(false);
  const [priceIndex, setPriceIndex] = useState<number | null>(null);

  function initializePlanData() {
    return {
      apps: autoSassAppsNames?.map((e) => ({
        ...e,
        enabled: false,
        items: [],
      })),
    };
  }

  useEffect(() => {
    if (item) {
      setPlanData({
        ...item.item,
        apps: item.item.apps?.map((app) => ({
          ...app,
          name:
            autoSassAppsNames?.find((i) => i.key === app.key)?.name || app.name,
        })),
      });
      setPriceIndex(item.index);
    } else {
      setPlanData(initializePlanData());
    }
  }, [item, autoSassAppsNames]);

  const canSubmit = useCallback(() => {
    return (
      !!planData?.name &&
      !!planData?.subHeader &&
      !!planData?.description &&
      !!planData?.price &&
      !!planData?.paymentPeriod &&
      !!planData?.trial
    );
  }, [planData]);

  const handleClose = () => {
    onClose();
    setPlanData(initializePlanData());
    setPriceIndex(null);
  };

  const handleSavePlan = async () => {
    if (!canSubmit()) return;
    setLoading(true);

    const queryData = {
      planIndex: 0,
      name: planData?.name,
      subHeader: planData?.subHeader,
      description: planData?.description,
      price: Number(planData?.price),
      apps: JSON.stringify(planData?.apps) || '[]',
      paymentPeriod: planData?.paymentPeriod,
      trial: Number(planData?.trial),
      currency: 'USD',
    };

    try {
      const response = await graphQlCall({
        queryTemplateObject:
          typeof priceIndex === 'number' && priceIndex >= 0
            ? queries.UPDATE_SAAS_PLAN_PRICE
            : queries.ADD_SAAS_PLAN_PRICE,
        values: { ...queryData, priceIndex },
        headerType: 'USER-AUTH',
      });

      if (!hasProduct && typeof priceIndex !== 'number') {
        await graphQlCall({
          queryTemplateObject: queries.ADD_SAAS_PLAN_PRODUCT,
          values: { name: queryData.name },
          headerType: 'USER-AUTH',
        });
      }

      onUpdateData(response.products[0]);
      fetchAutoSassDesign();
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (!isVisible) return null;

  return (
    <Popup onClose={handleClose} wrapperClassName={styles.popup}>
      <div className={styles.container}>
        <div className={styles.title}>Price Plan Settings</div>
        <Tabs
          tabs={[
            {
              title: 'Plan Details',
              content: (
                <PlanDetails
                  data={planData}
                  setData={setPlanData}
                  isUpdating={typeof priceIndex === 'number' && priceIndex >= 0}
                />
              ),
            },
            {
              title: 'Apps Include',
              content: <Applications data={planData} setData={setPlanData} />,
            },
          ]}
          tabsHeader={styles.tabBar}
          contentClassName={styles.contentClassName}
          className={styles.tabs}
        />
        <div className={styles.buttonsContainer}>
          <Button appearance="stroke" onClick={handleClose} disabled={loading}>
            Cancel Changes
          </Button>
          <Button disabled={loading || !canSubmit()} onClick={handleSavePlan}>
            {loading ? (
              <Loader size={16} color="#ffffff" />
            ) : (
              'Save and Go Back'
            )}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoSassAppsNames: state.autosaas.appsNames,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  fetchAutoSassDesign: () => dispatch(fetchAutoSassDesign()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductPopup);
