import { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Socket } from 'socket.io-client';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as MagicIcon } from 'Assets/icons/magic.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Link from 'UILib/Link/Link';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import { RootState } from 'store/rootReducer';
import { createSocket, getToken } from 'utils/Utils';
import { BookleTemplateBlock, IBroadcast, TemplateForm } from 'types';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
} from 'store/books/booksActions';
import { MenuItems } from '../../PageBookleTemplateEdior/Draggable/Sidebar/Sidebar';
import BroadcastEditor from './BroadcastEditor/BroadcastEditor';
import EmailTemplatesSelector from '../EmailTemplatesSelector/EmailTemplatesSelector';

import styles from './BroadcastGenerator.module.scss';

enum BrodcastGeneratorScreens {
  NameAndSubject,
  TemplateSelector,
  AiQuestions,
  Editor,
}

interface IProps {
  templateBlocks: BookleTemplateBlock[];
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
}

const BroadcastGenerator = ({
  updateBlocks,
  templateBlocks,
  templateBlockStyles,
  updateBlockStyles,
}: IProps) => {
  const history = useHistory();

  const [templateId, setTemplateId] = useState('');
  const { brodcastId } = useParams<{ brodcastId: string }>();

  const socket = useRef<Socket | null>(null);

  const [broadcastName, setBrodcastName] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  const [currentScreen, setCurrentScreen] = useState<BrodcastGeneratorScreens>(
    BrodcastGeneratorScreens.TemplateSelector
  );

  const [fetchingForms, setFetchingForms] = useState(false);
  const [templateForms, setTemplateForms] = useState<TemplateForm[]>([]);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValidationError, setFormValidationError] = useState(false);
  const [generationData, setGenerationData] = useState<
    Record<string, Record<string, string | number>>
  >({});
  const [mailDraftId, setMailDraftId] = useState<string>('');
  const [broadcast, setBroadcast] = useState<string>('');
  const [broadcastData, setBroadcastData] = useState<IBroadcast>();

  const handleEditorRedirectBack = () => {
    history.push(`/console/outreach/broadcasts`);
  };

  ///////////////////////TODO: refactoring area
  const [currentGenTaskId, setCurrentGenTaskId] = useState<string | null>(null);
  const [_tmp_nodes, _tmp_setNodes] = useState<any>({});
  /////////////////////////////////////////

  ////////////////////////////////////////////////////
  //TODO: need to refactor this and move everything in to one single class to eliminate copy/paste
  const updateGenerationBlockContent = (path: string, content: string) => {
    console.log('updating generation content block: ', path, content);

    const pathComponents = path.split('.');
    const variable = pathComponents[pathComponents.length - 1];

    for (const block of templateBlocks) {
      if (block.variable === variable) {
        block.generating = false;
        if (block.type === MenuItems.TEXT_BLOCK) {
          // if(block.text && block.text.length > 0){
          //   console.log('--------------',block.text);
          // }
          block.text = [
            {
              type: 'paragraph',
              children: [
                {
                  // ...block.text?.children, ///???
                  text: content,
                },
              ],
            },
          ];
        } else if (block.type === MenuItems.IMAGE_BLOCK) {
          block.image = content;
        }
        updateBlocks([...templateBlocks]);
        break;
      }
    }
  };

  const handleGenerationStart = async () => {
    console.log('GENERATION STARTED...');

    //mark all nodes that have variable as pennding for generation
    for (const block of templateBlocks) {
      if (block.variable) {
        block.generating = true;
      }
    }
    updateBlocks([...templateBlocks]);

    const task = await graphQlCall({
      queryTemplateObject: queries.CREATE_GENERATION_TASK_MUTATION,
      values: { templateId: templateId },
      headerType: 'USER-AUTH',
    });

    console.log('TASK:', task);

    //sett curent gen so it will be subscribed on updates from generation task
    setCurrentGenTaskId(task.task._id);

    const keys = Object.keys(generationData);
    console.log('generationData:', generationData[keys[0]]);
    const response = await graphQlCall({
      queryTemplateObject: queries.PUSH_DATA_TO_GENERATION_TASK_MUTATION,
      values: {
        taskId: task.task._id,
        data: JSON.stringify(generationData[keys[0]]),
        path: 'root',
      },
      headerType: 'USER-AUTH',
    });
    console.log('push data response: ', response);
  };

  useEffect(() => {
    if (!socket.current) {
      socket.current = createSocket();
      socket.current.on(
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );
      socket.current.on('connect', () => {
        console.log('connected to server');
      });
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          'generating-task-info-response',
          handleGenerationTaskUpdates
        );
      }
    };
  }, []);

  useEffect(() => {
    if (currentGenTaskId) {
      subscribeOnGenerationTaskUpdates(currentGenTaskId);
    }
  }, [currentGenTaskId]);

  const handleGenerationTaskUpdates = (payload: any) => {
    console.log('stuff is coming:', payload);
    if (payload.action === 'content generated') {
      if (payload.data) {
        updateGenerationBlockContent(payload.path, payload.data.result);
      }
    }
  };

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    if (socket.current) {
      console.log('test...');
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  /////////////////////////////////////////////////////////////
  const handleBrodcastCreation = async (withAi: boolean) => {
    if (broadcastName === '' || emailSubject === '') {
      setFormValidationError(true);
      return;
    }
    setFormValidationError(false);

    const mailDraft = await graphQlCall({
      queryTemplateObject: queries.CREATE_MAIL_DRAFT_MUTATION,
      values: {
        text: '',
        name: broadcastName,
        subject: emailSubject,
        data: JSON.stringify({
          styles: templateBlockStyles,
          blocks: templateBlocks,
        }),
        html: '',
      },
      headerType: 'USER-AUTH',
    });

    setMailDraftId(mailDraft._id);

    const broadcast = await graphQlCall({
      queryTemplateObject: queries.CREATE_BROADCAST_MUTATION,
      values: {
        name: broadcastName,
        mailDraftId: mailDraft._id,
      },
      headerType: 'USER-AUTH',
    });

    setBroadcast(broadcast?._id);

    if (withAi) {
      setCurrentScreen(BrodcastGeneratorScreens.AiQuestions);
    } else {
      setCurrentScreen(BrodcastGeneratorScreens.Editor);
    }
  };

  const handleNextClick = () => {
    const form = templateForms[currentFormIndex];
    const formValues = generationData[form.type];
    for (const key in formValues) {
      if (!formValues[key]) {
        setFormValidationError(true);
        return;
      }
    }
    setFormValidationError(false);
    if (currentFormIndex < templateForms.length - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    } else {
      handleGenerationStart();

      setCurrentScreen(BrodcastGeneratorScreens.Editor);
    }
  };

  const getFormElement = (
    form: string,
    element: {
      id: string;
      type: string;
      label: string;
    }
  ) => {
    const formData = generationData[form] ?? {};
    switch (element.type) {
      case 'Input':
        return (
          <Input
            border="stroke"
            className={styles.input}
            error={
              formValidationError && !formData[element.id]
                ? 'Value required'
                : undefined
            }
            value={formData[element.id]}
            onChange={(e) => {
              generationData[form][element.id] = e.target.value;
              setGenerationData(Object.assign({}, generationData));
            }}
          />
        );
      case 'Dropdown':
        return (
          <Dropdown
            type="stroke"
            label="Select"
            options={[]}
            value={formData[element.id]}
            hasErrors={formValidationError && !formData[element.id]}
            onChange={(newValue) => {
              generationData[form][element.id] = newValue;
              setGenerationData(Object.assign({}, generationData));
            }}
          />
        );
      case 'Textarea':
        return (
          <TextArea
            height={230}
            hasErrors={formValidationError && !formData[element.id]}
            value={formData[element.id]}
            onChange={(newValue) => {
              generationData[form][element.id] = newValue;
              setGenerationData(Object.assign({}, generationData));
            }}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (brodcastId) {
      setFetchingForms(true);
      graphQlCall({
        queryTemplateObject: queries.GET_ONE_BROADCAST,
        values: { id: brodcastId },
        headerType: 'USER-AUTH',
      })
        .then((data) => {
          if (data.mailDraft) {
            setMailDraftId(data.mailDraft._id);
            setBroadcastData(data);

            graphQlCall({
              queryTemplateObject: queries.GET_ONE_MAILDRAFT,
              values: { id: data.mailDraft._id },
              headerType: 'USER-AUTH',
            }).then((mailDraftData) => {
              updateBlocks(mailDraftData.data.blocks);
              updateBlockStyles(mailDraftData.data.styles);

              setCurrentScreen(BrodcastGeneratorScreens.Editor);
            });
          }
        })
        .finally(() => setFetchingForms(false));
    }
  }, []);

  useEffect(() => {
    if (templateForms.length > 0) {
      const form = templateForms[currentFormIndex];
      if (!generationData[form.type]) {
        const generationDataCopy = { ...generationData };
        generationDataCopy[form.type] = {};
        form.variables.forEach((variable) => {
          generationDataCopy[form.type][variable.id] = '';
        });
        setGenerationData(generationDataCopy);
      }
    }
  }, [currentFormIndex, templateForms]);

  useEffect(() => {
    if (templateId !== '') {
      setFetchingForms(true);

      graphQlCall({
        queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
        values: { id: templateId },
        headerType: 'USER-AUTH',
      })
        .then((data) => {
          if (data.actions) {
            setTemplateForms(data.actions.forms);
          } else {
            setTemplateForms([]);
          }
          updateBlocks(data.layout);
        })
        .finally(() => setFetchingForms(false));
    }
  }, [templateId]);

  const renderNameAndSubjectForm = () => {
    return (
      <>
        <div className={styles.header}>
          <MailIcon />
          <div className={styles.title}>Create Your Email</div>
          <div className={styles.subtitle}>
            Set a name to identify this email internally and choose a subject
            line that your audience will see.
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.formElement}>
            Internal Name
            <Input
              border="stroke"
              className={styles.input}
              error={
                formValidationError && broadcastName === ''
                  ? 'Value required'
                  : undefined
              }
              value={broadcastName}
              placeholder="Ex: Spring Sale Campaign"
              onChange={(e) => {
                setBrodcastName(e.target.value);
              }}
            />
          </div>
          <div className={styles.formElement}>
            Subject Line
            <Input
              border="stroke"
              className={styles.input}
              error={
                formValidationError && emailSubject === ''
                  ? 'Value required'
                  : undefined
              }
              value={emailSubject}
              placeholder="Ex: Don’t Miss Our Spring Sale!"
              onChange={(e) => {
                setEmailSubject(e.target.value);
              }}
            />
          </div>
          <div className={styles.buttonContainerVertical}>
            {templateForms.length > 0 ? (
              <>
                <Button
                  width={240}
                  appearance="highlighted"
                  onClick={() => {
                    handleBrodcastCreation(true);
                  }}
                  prefixIcon={<MagicIcon />}
                >
                  Write With AI
                </Button>

                <Button
                  appearance="stroke"
                  width={240}
                  onClick={() => {
                    handleBrodcastCreation(false);
                  }}
                >
                  Write On My Own
                </Button>
              </>
            ) : (
              <Button
                appearance="highlighted"
                width={240}
                onClick={() => {
                  handleBrodcastCreation(false);
                }}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderTemplateSelector = () => {
    return (
      <EmailTemplatesSelector
        onClick={(templateId: string) => {
          setTemplateId(templateId);
          setCurrentScreen(BrodcastGeneratorScreens.NameAndSubject);
        }}
      />
    );
  };

  const renderAiQuestionsScreen = () => {
    if (templateForms.length > 0) {
      return (
        <>
          <div className={styles.header}>
            <MailIcon />
            <div className={styles.title}>
              {templateForms[currentFormIndex].name}
            </div>
            <div className={styles.subtitle}>
              Describe and generate or skip this step and fill in your email
              from scratch
            </div>
          </div>
          <div className={styles.form}>
            {templateForms[currentFormIndex].variables.map((variable) => (
              <div key={variable.id} className={styles.formElement}>
                {variable.label}
                {getFormElement(templateForms[currentFormIndex].type, variable)}
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              appearance="stroke"
              onClick={() => setCurrentScreen(BrodcastGeneratorScreens.Editor)}
            >
              Skip this Step
            </Button>
            <Button
              appearance="solid"
              onClick={handleNextClick}
              postfixIcon={<ArrowRightIcon />}
            >
              Next
            </Button>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const renderCurrentScreen = (screen: BrodcastGeneratorScreens) => {
    switch (screen) {
      case BrodcastGeneratorScreens.NameAndSubject:
        return renderNameAndSubjectForm();

      case BrodcastGeneratorScreens.TemplateSelector:
        return renderTemplateSelector();

      case BrodcastGeneratorScreens.AiQuestions:
        return renderAiQuestionsScreen();

      case BrodcastGeneratorScreens.Editor:
        return null;

      default:
        return null;
    }
  };

  if (fetchingForms) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#4957d8" />
      </div>
    );
  }

  if (currentScreen == BrodcastGeneratorScreens.Editor) {
    return (
      <BroadcastEditor
        handleGoBack={handleEditorRedirectBack}
        emailSubject={emailSubject}
        broadcastId={broadcast || brodcastId}
        mailDraftId={mailDraftId}
        broadcastData={broadcastData}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Link
        to="/console/outreach/broadcasts"
        className={styles.backLink}
        prefixIcon={<Back />}
      >
        Dashboard
      </Link>

      {renderCurrentScreen(currentScreen)}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastGenerator);
