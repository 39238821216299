import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import { ReactComponent as Move } from 'Assets/icons/move2.svg';
import { ReactComponent as Settings } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as Ai } from 'Assets/icons/ai.svg';
import { BookleTemplateBlock } from 'types';
import { MenuItems } from '../Sidebar/Sidebar';
import { updateItem } from '../utils';
import ButtonBlockStylesContainer from './ButtonBlockStylesContainer/ButtonBlockStylesContainer';
import ContainerStyleBlock from './ContainerStyleBlock/ContainerStyleBlock';
import ImageBlockStyleContainer from './ImgageBlockStyleContainer/ImageBlockStyleContainer';
import DividerBlockStyleContainer from './DividerBlockStyleContainer/DividerBlockStyleContainer';
import ToolbarManipulatorPopup from '../toolbarManipulatorPopup/toolbarManipulatorPopup';
import AIToolbarActions from './AIToolbarActions/AIToolbarActions';
import GenerateAiImage from './GenerateAiImage/GenerateAiImage';
import More from './toolbarMore/more';

import styles from './toolbar.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  setDragging: (item: boolean) => void;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const SettingsModal = ({ item }: { item: BookleTemplateBlock }) => {
  switch (item?.type) {
    case MenuItems.ONE_SECTION:
    case MenuItems.TWO_SECTION:
    case MenuItems.THREE_SECTION:
    case MenuItems.FOUR_SECTION:
    case MenuItems.TEXT_BLOCK:
      return <ContainerStyleBlock item={item} />;
    case MenuItems.IMAGE_BLOCK:
      return <ImageBlockStyleContainer item={item} />;
    case MenuItems.DIVIDER_BLOCK:
      return <DividerBlockStyleContainer item={item} />;
    case MenuItems.BUTTON_BLOCK:
      return <ButtonBlockStylesContainer item={item} />;
    default:
      return <></>;
  }
};

const Toolbar = ({
  item,
  setDragging,
  templateBlocks,
  updateBlocks,
}: IProps) => {
  const [toolbarType, setToolbarType] = useState<
    'settings' | 'more' | 'ai' | undefined
  >(undefined);
  const divRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const [toolbarHeight, setToolbarTopHeight] = useState<number>(0);
  const [isReloadAiImage, setIsReloadAiImage] = useState<boolean>(false);

  const moveButtonRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MENU_ITEM',
    item,
    collect: (monitor) => {
      setDragging(monitor.isDragging());
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setToolbarTopHeight(entry.boundingClientRect.height);
        setIsAtTop(
          entry.intersectionRatio >= 0 && entry.boundingClientRect.top <= 80
        );
      },
      {
        root: null,
        rootMargin: '0px 0px -100% 0px',
        threshold: [1],
      }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [divRef]);

  const handleMoveStart = () => {
    setToolbarType(undefined);
  };

  const onClickToolbarButton = (key: 'settings' | 'more' | 'ai') => {
    setToolbarType(toolbarType === key ? undefined : key);
  };

  const onMoreClick = () => {
    setToolbarType(undefined);
  };

  const updateBlockItem = (key: string, value: string | boolean | never[]) => {
    updateItem(key, value, templateBlocks, updateBlocks, item);
  };

  const handleNextAiImage = (index: number) => {
    if (item.AIImages && index < item.AIImages?.length - 1) {
      updateBlockItem('imagePreview', item.AIImages[index + 1]);
    }
  };

  const handlePrevAiImage = (index: number) => {
    if (item.AIImages && index > 0) {
      updateBlockItem('imagePreview', item.AIImages[index - 1]);
    }
  };

  const handleRemoveAiActions = () => {
    updateBlockItem('imagePreview', '');
    updateBlockItem('imagePrompt', '');
    updateBlockItem('AIImages', []);
  };

  const handleSubmitAiActions = () => {
    updateBlockItem('image', item.imagePreview || item.image || '');
    handleRemoveAiActions();
  };

  if (isDragging) return null;

  return (
    <div
      className={`${styles.container} ${isDragging ? styles.dragging : ''}`}
      ref={divRef}
      style={isAtTop ? { top: 'initial', bottom: -50 } : {}}
      id={`toolbar-${item.id}`}
    >
      <AIToolbarActions
        item={item}
        handleEditImagePrompt={() => onClickToolbarButton('ai')}
        handleNextAiImage={handleNextAiImage}
        handlePrevAiImage={handlePrevAiImage}
        handleRemoveAiActions={handleRemoveAiActions}
        handleSubmitAiActions={handleSubmitAiActions}
        handleReloadAiImages={() => {
          if (toolbarType !== 'ai') {
            onClickToolbarButton('ai');
          }
          setIsReloadAiImage(true);
        }}
      />
      {!!toolbarType && (
        <ToolbarManipulatorPopup
          toolbarHeight={toolbarHeight}
          type={toolbarType === 'more' ? 'sm' : 'lg'}
          popupContent={
            toolbarType === 'more' ? (
              <More item={item} onClick={onMoreClick} />
            ) : toolbarType === 'settings' ? (
              <SettingsModal item={item} />
            ) : toolbarType === 'ai' ? (
              <GenerateAiImage
                item={item}
                isReloadAiImage={isReloadAiImage}
                setIsReloadAiImage={setIsReloadAiImage}
                handleCloseModal={() => {
                  if (toolbarType === 'ai') {
                    onClickToolbarButton('ai');
                  }
                }}
              />
            ) : null
          }
        />
      )}
      <div className={styles.toolbarItems}>
        {item.type === MenuItems.IMAGE_BLOCK && (
          <div
            className={clsx(styles.iconContainer, {
              [styles.activeButton]: toolbarType === 'ai',
            })}
            onClick={() => onClickToolbarButton('ai')}
          >
            <Ai />
          </div>
        )}
        <div
          className={styles.iconContainer}
          onMouseDown={handleMoveStart}
          onClick={() => setToolbarType(undefined)}
          ref={(node) => {
            drag(node);
            moveButtonRef.current = node;
          }}
        >
          <Move />
        </div>
        <div
          className={clsx(styles.iconContainer, {
            [styles.activeButton]: toolbarType === 'settings',
          })}
          onClick={() => onClickToolbarButton('settings')}
        >
          <Settings />
        </div>
        <div
          className={clsx(styles.iconContainer, {
            [styles.activeButton]: toolbarType === 'more',
          })}
          onClick={() => onClickToolbarButton('more')}
        >
          <MoreIcon />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
