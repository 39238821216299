import { useState, useEffect, ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import Input from 'UILib/Input/Input';

import styles from './SizeStyleControl.module.scss';

interface IProps {
  max: number;
  min?: number;
  step: number;
  theme?: 'dark' | 'light';
  className?: string;
  inputClassName?: string;
  customArrows?: ReactNode;
  onChange: (value: string) => void;
  value: string;
  title?: string;
  postfix?: JSX.Element;
  prefix?: JSX.Element;
  displayArrowsOnHover?: boolean;
  inputContainerClassName?: string;
}

const SizeStyleControl = ({
  displayArrowsOnHover = true,
  ...props
}: IProps) => {
  const [value, setValue] = useState<string>('');
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onValueChange = (e: any) => {
    if (
      Number(e.target.value).toFixed().length <= props.max.toString().length
    ) {
      props.onChange(e.target.value);
    }
  };

  const customArrows = useMemo(() => {
    if (displayArrowsOnHover) {
      return isHovered ? props.customArrows : null;
    }
    return props.customArrows;
  }, [displayArrowsOnHover, isHovered, props.customArrows]);

  return (
    <div
      className={clsx(styles.container, props.className, {
        [styles.darkContainer]: props.theme === 'dark',
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.titleContainer}>
        {props.prefix}
        <div className={styles.title}>{props.title}</div>
      </div>
      <div
        className={clsx(styles.inputContainer, props.inputContainerClassName)}
      >
        <Input
          min={props.min}
          max={props.max}
          step={props.step}
          value={value}
          onChange={onValueChange}
          type="number"
          customArrows={customArrows}
          postfixIcon={props.postfix && props.postfix}
          postfixIconClassName={styles.postfixIconClassName}
          className={clsx(
            styles.input,
            props.theme && styles[props.theme],
            props.inputClassName
          )}
          hideArrows
        />
      </div>
    </div>
  );
};

export default SizeStyleControl;
