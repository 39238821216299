import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { ReactComponent as CloseSvg } from 'Assets/icons/book/close.svg';

import styles from './Popup.module.scss';

interface IProps {
  onClose: () => void;
  children: React.ReactNode;
  popupClassName?: string;
  wrapperClassName?: string;
}

const Popup = ({
  onClose,
  children,
  popupClassName,
  wrapperClassName,
}: IProps) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  useEffect(() => {
    const modalContent = modalContentRef.current;
    if (modalContent) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'initial';
      };
    }
  }, []);

  return (
    <div
      className={clsx(styles.popup, popupClassName, {
        [styles.closing]: isClosing,
      })}
      ref={modalContentRef}
    >
      <div className={styles.overlay} onClick={handleClose} />
      <div className={clsx(styles.wrapper, wrapperClassName)}>
        <CloseSvg className={styles.closeIcon} onClick={handleClose} />
        {children}
      </div>
    </div>
  );
};

export default Popup;
