import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactComponent as Plus } from 'Assets/icons/plusAdd.svg';
import { DispatchType, RootState } from 'store/rootReducer';
import { fetchUserDetails } from 'store/user/userActions';
import { IUserDetails } from 'types';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';
import ProductItem from './ProductItem/ProductItem';
import AddProductPopup from './AddProductPopup/AddProductPopup';
import { IPrice, IProduct } from '../constants';

import styles from './Products.module.scss';

interface IProps {
  userDetails: IUserDetails;
  fetchUserDetails: () => void;
}

const ProductsTab = ({ userDetails, fetchUserDetails }: IProps) => {
  const [
    isAddProductModalVisible,
    setIsProductModalVisible,
  ] = useState<boolean>(false);
  const [selectedPrice, setSelectedPrice] = useState<{
    index: number;
    item: IPrice;
  }>();
  const [product, setProduct] = useState<IProduct>();
  const [hasProduct, setHasProduct] = useState<boolean>(false);
  const [showStripePopup, setShowStripePopup] = useState<boolean>(true);

  const history = useHistory();

  useEffect(() => {
    if (!userDetails._id) {
      fetchUserDetails();
    }
  }, [userDetails]);

  const fetchAutoSassProducts = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: queries.GET_MY_SASS_PLAN_PRODUCTS,
        headerType: 'USER-AUTH',
      });
      setProduct(response?.products[0]);
      setHasProduct(!!response?.products?.length);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchAutoSassProducts();
  }, []);

  const onCloseProductModal = () => {
    setIsProductModalVisible(false);
    setSelectedPrice(undefined);
  };

  return (
    <>
      <AddProductPopup
        isVisible={isAddProductModalVisible}
        onClose={onCloseProductModal}
        item={selectedPrice}
        hasProduct={hasProduct}
        onUpdateData={(item) => setProduct(item)}
      />
      <div className={styles.container}>
        {userDetails.stripePublicKey ? (
          <div className={styles.titleContainer}>
            <div className={styles.titleText}>
              <div className={styles.title}>Plans</div>
              <div className={styles.subtitle}>
                Customize your plans to share with audience
              </div>
            </div>
            <Button
              width={220}
              height={40}
              name="Add Plan"
              postfixIcon={<Plus />}
              className={styles.addButton}
              onClick={() => setIsProductModalVisible(true)}
            >
              Add Plan
            </Button>
          </div>
        ) : (
          showStripePopup &&
          userDetails._id && (
            <Popup onClose={() => setShowStripePopup(false)}>
              <div className={styles.stripePopup}>
                <div className={styles.stripePopupTitle}>
                  Connect Stripe Account
                </div>
                <div className={styles.stripePopupSubtitle}>
                  To create products, you need to connect your Stripe account
                </div>
                <Button
                  width={220}
                  height={40}
                  appearance="highlighted"
                  name="Connect Stripe"
                  onClick={() => {
                    history.push('/console/settings/billing-settings');
                  }}
                >
                  Connect Stripe
                </Button>
              </div>
            </Popup>
          )
        )}
        <div className={styles.products}>
          {product?.prices.map((price, index) => (
            <ProductItem
              key={index}
              price={price}
              handleClick={(item) => {
                setSelectedPrice({ index, item });
                setIsProductModalVisible(true);
              }}
              index={index}
              onUpdateData={(item) => setProduct(item)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  fetchUserDetails: () => dispatch(fetchUserDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTab);
