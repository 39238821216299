import { useEffect, useState } from 'react';
import { notification } from 'antd';
import Popup from 'UILib/Popup/Popup';
import Input from 'UILib/Input/Input';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import queries from 'graphql/queries';
import { UserInfo } from 'classes/UserInfo';
import { IProduct } from 'Components/AutoSaas/constants';
import { getUserId } from 'utils/Utils';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as PersonIcon } from 'Assets/icons/person.svg';

import styles from './AddCustomerPopup.module.scss';

interface IProps {
  onCustomerCreateSuccess: () => void;
  onClose: () => void;
}

const AddCustomerPopup = ({ onClose, onCustomerCreateSuccess }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<string>();
  const [product, setProduct] = useState<IProduct>();

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_MY_SASS_PLAN_PRODUCTS,
      headerType: 'USER-AUTH',
    }).then((response) => {
      setProduct(response?.products[0]);
    });
  }, []);

  const handleAddCustomer = async () => {
    if (!name || !email || !selectedProduct) return;

    let saasId;
    const userInfo = await UserInfo.getInstance().getInfo();
    if (userInfo.scopes.includes('SAAS_OWNER')) {
      saasId = getUserId();

      setLoading(true);
      try {
        await graphQlCall({
          queryTemplateObject: queries.CREATE_USER_MUTATION,
          values: { email, name, planId: selectedProduct, saasId },
          headerType: 'USER-AUTH',
        });
        onCustomerCreateSuccess();
      } catch (error: any) {
        notification.error({
          message: error.message?.replace('GRAPH QL ERROR: ', ''),
          duration: 3,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Popup onClose={onClose}>
      <div className={styles.modalBody}>
        <PersonIcon className={styles.personIcon} fill="black" />
        <div className={styles.popupHeader}>Add Customer</div>
        <div className={styles.popupText}>
          Enter their name, email address, and select the desired product.
        </div>
        <div className={styles.form}>
          <div>
            Name
            <Input
              border="stroke"
              placeholder="Customer Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div>
            Email
            <Input
              type="email"
              border="stroke"
              placeholder="Customer's Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div>
            Product
            <Dropdown
              size="large"
              type="stroke"
              value={selectedProduct}
              label="Select Product"
              options={
                product?.prices.map((price) => ({
                  label: price.name,
                  value: price.stripeId,
                })) ?? []
              }
              onChange={setSelectedProduct}
            />
          </div>
        </div>
        <Button
          className={styles.createButton}
          onClick={handleAddCustomer}
          disabled={!name || !email || !selectedProduct}
        >
          {loading ? <Loader color="#ffffff" size={16} /> : 'Add Customer'}
        </Button>
      </div>
    </Popup>
  );
};

export default AddCustomerPopup;
