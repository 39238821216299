export const CREATE_GENERATION_TEMPLATE_MUTATION = {
  operationName: 'createGenerationTemplate',
  query: `mutation createGenerationTemplate(
          <KEYS>
        ) {
          createGenerationTemplate(
          <VALUES>
          ) {
            _id
            createdAt
            updatedAt
          }
        }`,
};
