export interface Prompt {
  name: string;
  prompt: string;
  description: string;
}

export type ParsedAction = {
  forms: {
    variables: {
      type: string;
    }[];
  }[];
  postFunctions: PostFunction[];
};

export type PostFunction = {
  each: {
    type: string;
    prompt: string;
    id: string;
    variable: string;
  };
};

export type Block = {
  id: string | number;
  type: string;
  items?: {
    id: string | number;
    items: {
      id: string | number;
      type: string;
    }[];
  }[];
  text?: {
    type: string;
    children: {
      text: string;
    }[];
  }[];
};

export const prompts: Prompt[] = [
  {
    name: 'Workout Generator',
    prompt: 'Create a workout routine',
    description:
      'An app that creates customized workout plans based on user goals and preferences.',
  },
  {
    name: 'Social Media Planner',
    prompt: 'Make a social media planner',
    description:
      'An app to help plan and schedule social media posts across multiple platforms.',
  },
  {
    name: 'Financial Calculator',
    prompt: 'Build a financial calculator',
    description:
      'A tool that provides various financial calculations such as loan payments, interest rates, and more.',
  },
  {
    name: 'Finance Tracker',
    prompt: 'Create a personal finance tracker',
    description:
      'An app to track income, expenses, and budgeting for better financial management.',
  },
  {
    name: 'Meal Planner',
    prompt: 'Build a meal planning assistant',
    description:
      'An app that generates weekly meal plans with shopping lists and recipe suggestions.',
  },
  {
    name: 'Virtual Coach',
    prompt: 'Design a virtual workout coach',
    description:
      'A virtual fitness coach that provides personalized exercise routines and progress tracking.',
  },
  {
    name: 'Habit Tracker',
    prompt: 'Develop a habit tracking app',
    description:
      'An app to track and develop healthy habits with reminders and progress tracking.',
  },
  {
    name: 'Content Planner',
    prompt: 'Make a social media content planner',
    description:
      'An app to help create and schedule content for social media platforms to ensure consistent posting.',
  },
  {
    name: 'Time Manager',
    prompt: 'Create a time management tool',
    description:
      'An app to help users manage their daily tasks and schedules with reminders and to-do lists.',
  },
  {
    name: 'Resume Builder',
    prompt: 'Build a resume builder with AI',
    description:
      'A tool that helps users build a professional resume with AI-generated suggestions and templates.',
  },
  {
    name: 'Itinerary Generator',
    prompt: 'Design a travel itinerary generator',
    description:
      'An app that generates travel itineraries with destinations, activities, and accommodations suggestions.',
  },
  {
    name: 'Budget Calculator',
    prompt: 'Create a budgeting calculator',
    description:
      'A tool for creating and managing a personal budget, helping users allocate funds and track expenses.',
  },
  {
    name: 'Study Planner',
    prompt: 'Develop a personalized study plan tool',
    description:
      'An app to create customized study plans based on exam dates, study materials, and goals.',
  },
  {
    name: 'Routine Generator',
    prompt: 'Make a workout routine generator',
    description:
      'An app that generates workout routines based on user input, like fitness level and goals.',
  },
  {
    name: 'Goal Setter',
    prompt: 'Create a daily goal setting app',
    description:
      'An app that helps users set and track their daily goals to stay organized and productive.',
  },
  {
    name: 'Calorie Counter',
    prompt: 'Build a meal calorie counter',
    description:
      'A tool that tracks the calorie content of meals to help users monitor their daily intake.',
  },
  {
    name: 'Expense Tracker',
    prompt: 'Design a financial expense tracker',
    description:
      'An app to track daily expenses and categorize spending to stay within budget.',
  },
  {
    name: 'Mental Health Bot',
    prompt: 'Develop a mental health support chatbot',
    description:
      'A chatbot app that offers mental health support, coping strategies, and emotional check-ins.',
  },
  {
    name: 'Skincare Routine',
    prompt: 'Create a personalized skincare routine app',
    description:
      'An app that recommends personalized skincare routines based on skin type and concerns.',
  },
  {
    name: 'Home Organizer',
    prompt: 'Build a home organization planner',
    description:
      'A tool to help organize and declutter your home, providing checklists and reminders.',
  },
  {
    name: 'Project Tracker',
    prompt: 'Design a project management tracker',
    description:
      'An app to track projects, deadlines, and team progress, offering task assignments and scheduling.',
  },
  {
    name: 'Reading List',
    prompt: 'Make a custom reading list generator',
    description:
      'An app that generates personalized book recommendations based on reading preferences and genres.',
  },
  {
    name: 'Productivity App',
    prompt: 'Create a productivity boosting app',
    description:
      'An app that helps users boost their productivity by offering techniques, reminders, and progress tracking.',
  },
];

export const defaultTemplateActionSet = {
  id: 'buwgf5hfj',
  type: 'UserInput',
  forms: [
    {
      type: 'Info',
      variables: [],
      name: 'Questions',
    },
  ],
  preFunctions: [],
  postFunctions: [],
};
