import Button from 'UILib/Button/Button';
import { ReactComponent as Arrow } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as Edit } from 'Assets/icons/edit2.svg';
import { ReactComponent as Reload } from 'Assets/icons/reload2.svg';
import { ReactComponent as Check } from 'Assets/icons/checkMark.svg';
import { ReactComponent as Close } from 'Assets/icons/remove-icon.svg';
import { BookleTemplateBlock } from 'types';
import { MenuItems } from '../../Sidebar/Sidebar';

import styles from './AIToolbarActions.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  handlePrevAiImage: (index: number) => void;
  handleNextAiImage: (index: number) => void;
  handleSubmitAiActions: () => void;
  handleRemoveAiActions: () => void;
  handleEditImagePrompt: () => void;
  handleReloadAiImages: () => void;
}

const AIToolbarActions = ({
  item,
  handlePrevAiImage,
  handleNextAiImage,
  handleSubmitAiActions,
  handleRemoveAiActions,
  handleEditImagePrompt,
  handleReloadAiImages,
}: IProps) => {
  if (
    !(
      item.type === MenuItems.IMAGE_BLOCK &&
      !!item.imagePreview &&
      !!item.AIImages?.length
    )
  ) {
    return null;
  }

  return (
    <div className={styles.aiImageToolbar}>
      <div className={styles.arrowsContainer}>
        <Button
          width={40}
          height={40}
          prefixIcon={<Arrow />}
          appearance="solid"
          onClick={() =>
            handlePrevAiImage(
              item.AIImages?.indexOf(item?.imagePreview || '') || 0
            )
          }
        />
        <div>
          {item.AIImages.indexOf(item.imagePreview) +
            1 +
            '/' +
            item.AIImages.length}
        </div>
        <Button
          width={40}
          height={40}
          prefixIcon={<Arrow className={styles.next} />}
          appearance="solid"
          onClick={() =>
            handleNextAiImage(
              item.AIImages?.indexOf(item?.imagePreview || '') || 0
            )
          }
        />
      </div>
      <div className={styles.actions}>
        <Button
          width={40}
          height={40}
          prefixIcon={<Edit className={styles.next} />}
          appearance="solid"
          onClick={handleEditImagePrompt}
        />
        <Button
          width={40}
          height={40}
          prefixIcon={<Reload className={styles.next} />}
          appearance="solid"
          onClick={handleReloadAiImages}
        />
        <Button
          width={40}
          height={40}
          prefixIcon={<Check className={styles.checkMark} />}
          appearance="solid"
          onClick={handleSubmitAiActions}
        />
        <Button
          width={40}
          height={40}
          prefixIcon={<Close className={styles.next} />}
          appearance="solid"
          onClick={handleRemoveAiActions}
        />
      </div>
    </div>
  );
};

export default AIToolbarActions;
