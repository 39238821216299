export const GET_ONE_GENERATION_TEMPLATE = {
  operationName: 'getOneGenerationTemplate',
  query: `query getOneGenerationTemplate ($id: String!){
          getOneGenerationTemplate(id: $id) {
            _id
            name
            layout
            actions { 
              id
              type              
              forms {
                type
                name
                variables {
                  id
                  type
                  label
                  options
                }
              }
              prompt
              metadata
              postFunctions
            }
            createdAt
            updatedAt
          }
        }`,
};
