import { useState } from 'react';
import { IAccount } from 'Components/Accounts/types';
import { IDuplicateFunnelOtherAccountPayload } from 'types';
import WordIcon from 'Assets/icons/worldBlack.svg';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';

import styles from './DuplicateFunnelOtherAccountPopup.module.scss';

interface IProps {
  owner: string;
  funnelId: string;
  open: boolean;
  accounts: IAccount[];
  onClose: () => void;
  onDuplicate: (payload: IDuplicateFunnelOtherAccountPayload) => void;
}

interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const DuplicateFunnelOtherAccountPopup = (props: IProps) => {
  const [selectedAccountSub, setSelectedAccountSub] = useState('');

  const accountOptions: ISelectOption[] = props.accounts.map((account) => ({
    label: account.workspace ? account.workspace : account.name,
    value: account.owner,
  }));

  const processDuplicateFunnel = async () => {
    if (!!selectedAccountSub) {
      props.onDuplicate({
        targetAccountOwner: selectedAccountSub,
        sourceAccountOwner: props.owner,
        funnelId: props.funnelId,
      });

      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedAccountSub('');
    props.onClose();
  };

  if (!props.open) {
    return null;
  }

  return (
    <Popup onClose={handleClose} wrapperClassName={styles.container}>
      <div className={styles.modal}>
        <img src={WordIcon} alt="" className={styles.mainImage} />
        <h2 className={styles.header}>Copy to Another Account</h2>
        <p className={styles.additionalText}>
          Choose the account you want to copy this funnel
        </p>
        <Dropdown
          options={accountOptions}
          onChange={setSelectedAccountSub}
          value={selectedAccountSub}
          label="Select Account"
          type="stroke"
          className={styles.dropdown}
        />
        <div className={styles.buttonsBlock}>
          <Button
            onClick={handleClose}
            className={styles.button}
            appearance="stroke"
          >
            Cancel
          </Button>
          <Button
            onClick={processDuplicateFunnel}
            disabled={!selectedAccountSub}
            className={styles.button}
          >
            Copy
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default DuplicateFunnelOtherAccountPopup;
