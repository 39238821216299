import { connect } from 'react-redux';
import { useState } from 'react';
import { ReactComponent as GlobeIcon } from 'Assets/icons/domainIcon.svg';
import { addDomain, addDomainPopupAction } from 'store/domains/domainsActions';
import { RootState } from 'store/rootStore';
import Button from 'UILib/Button/Button';
import Input from 'UILib/Input/Input';
import Popup from 'UILib/Popup/Popup';

import styles from './AddDomainPopup.module.scss';

interface IProps {
  openAddDomainPopup: boolean;
  toggleAddDomainPopup: (payload: boolean) => void;
  addDomain: (name: string, projectId?: string) => void;
}

const AddDomainPopup = ({
  openAddDomainPopup,
  toggleAddDomainPopup,
  addDomain,
}: IProps) => {
  const [domainName, setDomainName] = useState<string>();

  if (!openAddDomainPopup) return null;

  const handleSubmit = () => {
    if (!domainName) return;

    let name = domainName;
    if (name.indexOf('http') === 0) {
      name = name.replace(/http\w?:\/\//, '');
    }

    addDomain(name.toLowerCase());
    toggleAddDomainPopup(false);
    setDomainName(undefined);
  };

  return (
    <Popup
      onClose={() => toggleAddDomainPopup(false)}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <GlobeIcon width={50} height={50} fill="black" />
        <div className={styles.title}>Add Your Own Domain</div>
        <div className={styles.subtitle}>
          Enter the domain name you already own below <br /> and we will take
          care of the rest.
        </div>
        <div className={styles.formBlock}>
          <Input
            className={styles.domainInput}
            border="stroke"
            placeholder="Type Your Domain Name"
            value={domainName}
            onChange={(e) => setDomainName(e.target.value)}
            height={50}
          />
          <div className={styles.tipMessage}>For example: www.yourname.com</div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          height={50}
          appearance="highlighted"
          onClick={handleSubmit}
          disabled={!domainName}
        >
          Add Domain
        </Button>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  openAddDomainPopup: state.domains.openAddDomainPopup,
});

const mapDispatchToProps = {
  toggleAddDomainPopup: (payload: boolean) => addDomainPopupAction(payload),
  addDomain: (name: string, projectId?: string) => addDomain(name, projectId),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDomainPopup);
