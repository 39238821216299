import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './PillTab.module.scss';

interface IProps {
  id: string | number;
  theme?: 'light' | 'dark';
  height?: number;
  prefixIcon?: ReactNode;
  postfixIcon?: ReactNode;
  children?: ReactNode;
  selected?: boolean;
  onSelect?: (id: string | number) => void;
}

const PillTab = ({
  id,
  children,
  selected,
  height = 40,
  postfixIcon,
  prefixIcon,
  theme = 'light',
  onSelect,
}: IProps) => {
  return (
    <div
      className={clsx(styles.tab, styles[theme], {
        [styles.selected]: selected,
      })}
      onClick={() => {
        if (onSelect) onSelect(id);
      }}
      style={{ height }}
    >
      {prefixIcon}
      {children}
      {postfixIcon}
    </div>
  );
};

export default PillTab;
