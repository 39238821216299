import { useEffect, useState } from 'react';
import queries from 'graphql/queries';
import IconWithBackground from 'UILib/IconWithBackground/IconWithBackground';
import DocumentCard from 'Components/BookBuilder/DocumentCard/DocumentCard';
import Carousel from 'UILib/Carousel/Carousel';
import { emailTemplatesGroups } from './constants';
import styles from './EmailTemplatesSelector.module.scss';
import { graphQlCall } from 'graphql/utils';
import { EmailTemplatesGroup } from '../types';

interface IProps {
  onClick: (templateId: string) => void;
}

const EmailTemplatesSelector = (props: IProps) => {
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplatesGroup[]>(
    emailTemplatesGroups
  );

  useEffect(() => {
    getGenerationTemplates();
  }, []);

  const getGenerationTemplates = async () => {
    const templateData = await graphQlCall({
      queryTemplateObject: queries.GET_GENERATION_TEMPLATES,
      headerType: 'USER-AUTH',
    });

    emailTemplates[0].templates = [];
    for (const template of templateData) {
      emailTemplates[0].templates.push({
        label: template.name,
        image: template.thumbnail,
        id: template._id,
      });
    }

    setEmailTemplates([...emailTemplates]);
  };

  return (
    <>
      <div className={styles.pageHeader}>
        <div className={styles.title}>{'Choose Your Email Template'}</div>
        <div className={styles.subtitle}>
          {'Quickly and easy create email that will meet your needs!'}
        </div>
      </div>
      <div className={styles.content}>
        {emailTemplates.map(
          (group, index) =>
            group.templates.length > 0 && (
              <div key={index} className={styles.templateGroup}>
                <div className={styles.header}>
                  <IconWithBackground
                    size={30}
                    showBackground
                    Icon={group.icon}
                    color={group.color}
                  />
                  {group.title}
                </div>
                <div className={styles.documentsList}>
                  {group.templates.length <= 6 ? (
                    group.templates.map((template) => (
                      <DocumentCard
                        key={template.id}
                        image={template.image}
                        label={template.label}
                        onClick={() => {
                          props.onClick(template.id!);
                        }}
                      />
                    ))
                  ) : (
                    <Carousel
                      gap={20}
                      hideIndicator
                      cardsPerSlide={6}
                      cards={group.templates.map((template) => ({
                        image: (
                          <DocumentCard
                            key={template.id}
                            image={template.image}
                            label={template.label}
                          />
                        ),
                      }))}
                      onCardSelect={(index) => {
                        const id = group.templates[index]?.id;
                        props.onClick(id!);
                      }}
                    />
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default EmailTemplatesSelector;
