export const GET_GENERATION_TEMPLATES_QUERY_WITH_PAGINATION = {
  operationName: 'getGenerationTemplatesWithPagination',
  query: `query getGenerationTemplatesWithPagination ($skip: Float!, $limit: Float!, $type: String!, $search: String, $sortBy: String, $sortAsc: Boolean) {
        getGenerationTemplatesWithPagination(skip: $skip, limit: $limit, type: $type, search: $search, sortBy: $sortBy, sortAsc: $sortAsc) {
        templates {
            _id
            name
            type
            createdAt
            updatedAt
            thumbnail
        }
        total
      }
    }`,
};
