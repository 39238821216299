import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { fetchUserAccess } from 'store/user/userActions';
import { DispatchType, RootState } from 'store/rootReducer';
import {
  setActiveAccountAction,
  fetchAccountsAction,
} from 'store/accounts/accountsActions';
import { getAutoSassApps } from 'store/autosaas/autosaasActions';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import {
  isAdmin,
  getUserId,
  getUserName,
  getActiveSubId,
  switchSubAccount,
} from 'utils/Utils';
import { ReactComponent as ChangePageSettingSvg } from 'Assets/icons/changePageSetting.svg';
import { ReactComponent as ChangePageLogoutSvg } from 'Assets/icons/changePageLogout.svg';
import { ReactComponent as ChangePageHelpSvg } from 'Assets/icons/changePageHelpCenter.svg';
import { ReactComponent as PlusCircleIcon } from 'Assets/icons/plusInCircle.svg';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as AccountIcon } from 'Assets/icons/blankThumbnail.svg';
import { ReactComponent as RocketIcon } from 'Assets/icons/rocket.svg';
import { ReactComponent as DotsSvg } from 'Assets/icons/copyCraft/dots.svg';
import { ReactComponent as GoBack } from 'Assets/icons/sideMenu/go-back.svg';
import { ReactComponent as AppIcon } from 'Assets/icons/appIcons/templates.svg';
import { IAccount } from 'Components/Accounts/types';
import { truncateString } from 'utils/helpers';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import {
  AutoSassIconsOptions,
  menuItems,
  PageType,
  pageTypes,
  submenuItems,
} from './constants';
import { IProps, ActiveSettingPageType, ActivePageType } from './types';
import AgenciesDropDown from 'Components/Accounts/AgenciesDropDown/AgenciesDropDown';
import AutoSassAppsSettingsModal from 'Components/AutoSassAppsSettingsModal/AutoSassAppsSettingsModal';
import LogoSvg from 'Assets/icons/sideMenu/logo.svg';

import s from './SideMenu.module.scss';

const SideMenu = (props: IProps) => {
  const subAccounts = props.accounts;
  const hasAutoSaasAccess = props.userDetails.scopes?.includes('SAAS_OWNER');

  const history = useHistory();
  const location = useLocation();
  const [activePage, setActivePage] = useState<
    ActivePageType | ActiveSettingPageType | null
  >(null);
  const [accountAccessComplete, setAccountAccessComplete] = useState<boolean>(
    false
  );
  const [isAppSettingModalOpen, setIsAppSettingsModalOpen] = useState<boolean>(
    false
  );
  const [selectedAutoSassApp, setSelectedAutoSassApp] = useState<
    IAutoApp | undefined
  >(undefined);
  const [subMenu, setSubMenu] = useState<'outreach' | 'settings'>();
  const [openAgencyMenu, setOpenAgencyMenu] = useState(false);
  const [activeAccount, setActiveAccount] = useState<{
    name?: string;
    thumbnail?: string;
  }>();
  const [menuPages, setMenuPages] = useState<PageType[]>(
    menuItems(props.userAccess, isAdmin(), !!hasAutoSaasAccess)
  );

  const modalRef = useRef<HTMLDivElement>(null);
  UseOnClickOutside(modalRef, (e) => setOpenAgencyMenu(false));

  const hasAgencyAccess =
    props.userDetails.scopes?.includes('AGENCY_OWNER') ||
    props.userDetails.scopes?.includes('ADMIN');

  useLayoutEffect(() => {
    const init = async () => {
      for (const e of [
        ...pageTypes,
        ...(props?.autoApps?.map(
          (item) => item.name?.toLowerCase().replace(/\s+/g, '') || ''
        ) ?? []),
      ]) {
        const path = location.pathname.split('/');

        if (path[2] === e || path[3] === e) {
          setActivePage(e as ActivePageType | ActiveSettingPageType);
          break;
        }
      }
      await props.getAccountAccess();
      setAccountAccessComplete(true);
    };

    init();
  }, [props.autoApps]);

  useEffect(() => {
    let pages = menuItems(props.userAccess, isAdmin(), !!hasAutoSaasAccess);
    if (props.autoApps?.length && hasAutoSaasAccess) {
      pages = [
        ...pages,
        ...props.autoApps.map((page: any) => ({
          key: page.name.toLowerCase().replace(/\s+/g, ''),
          name: page.name,
          icon: AutoSassIconsOptions.find((item) => item.path === page.iconUrl)
            ?.icon || <AppIcon />,
          access: true,
          redirectUrl: `/console/app/${page.name
            .toLowerCase()
            .replace(/\s+/g, '')}/${page?._id}`,
          autosaas: {
            openSettingModal: true,
            appId: page._id,
          },
        })),
      ];
    }

    setMenuPages(pages);
  }, [props.autoApps, hasAutoSaasAccess, props.userAccess]);

  useEffect(() => {
    if (props.getAutoSassApps && props.userAccess) {
      props.getAutoSassApps();
    }
  }, [hasAutoSaasAccess]);

  useEffect(() => {
    const id = getActiveSubId();

    const account = subAccounts.find((e) => e._id === id);

    setActiveAccount(
      account
        ? { name: account.name, thumbnail: account.thumbnail }
        : {
            name: props.userDetails.name,
            thumbnail: props.userDetails.thumbnail,
          }
    );
  }, [subAccounts, props.userDetails]);

  const fetchSubAccounts = async () => {
    props.getAccounts();
  };

  const getUserAccess = async () => {
    let access = {
      ...props.userAccess,
    };

    const location = window.location.pathname;

    if (
      (!access.bookle && location.includes('books')) ||
      (!access.funnel && location.includes('projects')) ||
      (!access.copyCraft && location.includes('copy-craft')) ||
      (!access.agency && location.includes('agency')) ||
      (!props.userDetails.scopes?.includes('SAAS_OWNER') &&
        location.includes('autosaas')) ||
      (!access.podcast && location.includes('podcaster'))
    ) {
      window.open('/console/settings', '_self');
    }

    if (access.agency) {
      await fetchSubAccounts();
    }
    if (
      !access.bookle &&
      !access.copyCraft &&
      !access.funnel &&
      // !access.contacts &&
      props.onShowNoSubscriptionAlert
    ) {
      props.onShowNoSubscriptionAlert();
    }
  };

  useEffect(() => {
    if (accountAccessComplete) {
      getUserAccess();
    }
  }, [
    props.userAccess,
    props.onShowNoSubscriptionAlert,
    accountAccessComplete,
  ]);

  const handleLinkClick = (
    link: string,
    newActivePage?: ActivePageType | ActiveSettingPageType
  ) => {
    history.push(link);
    if (newActivePage) {
      setActivePage(newActivePage);
    }
  };

  const handleCLickManage = () => {
    history.push('/console/agency');
  };

  const handleSwitchAccount = async (account: IAccount) => {
    props.setActiveAccount(account);
  };

  const handleSwitchToAgencyAccount = async () => {
    const id = getUserId() || '';
    const owner = getUserName();
    await switchSubAccount(id, owner);
    const link = `/console/projects`;
    window.open(link, '_self');
  };

  useEffect(() => {
    if (!activePage) return;

    if (
      [
        'domains-senders',
        'domains',
        'billing-settings',
        'billing',
        'zapier',
      ].includes(activePage)
    ) {
      setSubMenu('settings');
    } else if (['outreach', 'templates', 'broadcasts'].includes(activePage)) {
      setSubMenu('outreach');
    } else if (activePage.includes('funnel-templates')) {
      setActivePage('projects');
    }
  }, [activePage]);

  const handleOpenAgencyMenu = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setOpenAgencyMenu(true);
  };

  return (
    <>
      <div className={s.menuWrapper}>
        <div className={s.topItem}>
          <div className={s.logo}>
            <img
              src={props.autosaasLogo || LogoSvg}
              onClick={() => history.push('/console')}
              alt="logo"
            />
          </div>
          <div className={s.menuItems}>
            {subMenu ? (
              <>
                <div
                  className={clsx(s.menuItem, s.topItem)}
                  onClick={() => setSubMenu(undefined)}
                >
                  <div className={s.menuIcon}>
                    <GoBack />
                  </div>
                  <span>Go Back</span>
                </div>
                {submenuItems[subMenu].map(
                  (e, index) =>
                    !!e.access && (
                      <div
                        className={clsx(s.menuItem, s.topItem, {
                          [s.activeItem]:
                            (activePage === subMenu && index === 0) ||
                            activePage === e.key,
                        })}
                        onClick={() =>
                          handleLinkClick(
                            e.redirectUrl,
                            (e.key as ActiveSettingPageType) || undefined
                          )
                        }
                        key={e.key}
                      >
                        <div
                          className={clsx(s.menuIcon, {
                            [s.outreach]: subMenu === 'outreach',
                          })}
                        >
                          {e.icon}
                        </div>
                        <span>{e.name}</span>
                      </div>
                    )
                )}
              </>
            ) : (
              <>
                {hasAutoSaasAccess ? (
                  <div
                    className={clsx(s.menuItem, {
                      [s.activeItem]: activePage === 'autosaas',
                    })}
                    onClick={() =>
                      handleLinkClick('/console/autosaas', 'autosaas')
                    }
                  >
                    <RocketIcon />
                    <span>AutoSaas</span>
                  </div>
                ) : (
                  hasAgencyAccess && (
                    <div
                      className={clsx(s.menuItem, s.agencyItem, {
                        [s.activeItem]: activePage === 'agency',
                      })}
                      onClick={() =>
                        handleLinkClick('/console/agency', 'agency')
                      }
                    >
                      <div className={s.agencyImage}>
                        {activeAccount?.thumbnail ? (
                          <img
                            src={activeAccount?.thumbnail}
                            className={s.agencyLogo}
                            alt="agency logo"
                          />
                        ) : (
                          <span className={s.thumbnailBox}>
                            <AccountIcon className={s.photoPlaceholder} />
                          </span>
                        )}
                        <span>{truncateString(activeAccount?.name, 25)}</span>
                      </div>
                      <DotsSvg
                        onClick={handleOpenAgencyMenu}
                        className={s.dots}
                      />
                    </div>
                  )
                )}
                {(hasAgencyAccess || hasAutoSaasAccess) && (
                  <div ref={modalRef} className={s.agency}>
                    {openAgencyMenu && (
                      <AgenciesDropDown
                        accounts={subAccounts}
                        onSwitchAccount={handleSwitchAccount}
                        onManageClick={() => handleCLickManage()}
                        onSwitchToAgencyAccount={() =>
                          handleSwitchToAgencyAccount()
                        }
                        userDetails={props.userDetails}
                      />
                    )}
                  </div>
                )}
                {menuPages?.map(
                  (e, index) =>
                    !!e.access && (
                      <div
                        className={clsx(s.menuItem, s.topItem, {
                          [s.activeItem]: activePage === e.key,
                        })}
                        onClick={() =>
                          e.key === 'outreach'
                            ? setSubMenu('outreach')
                            : handleLinkClick(
                                e?.redirectUrl ?? '',
                                (e.key as ActivePageType) || undefined
                              )
                        }
                        key={index}
                      >
                        {e.icon}
                        <span className={s.pageName}>
                          {props.autoSassAppsNames?.find(
                            (item) =>
                              item?.key?.toLocaleLowerCase() ===
                              e.autosaas?.key?.toLocaleLowerCase()
                          )?.name || e.name}
                        </span>
                        {(!!e.autosaas?.redirectUrl ||
                          e.autosaas?.openSettingModal) &&
                          hasAutoSaasAccess && (
                            <SettingsIcon
                              className={s.settingsIcon}
                              onClick={(ev) => {
                                ev.stopPropagation();
                                ev.preventDefault();
                                if (!!e.autosaas?.redirectUrl) {
                                  handleLinkClick(
                                    e?.autosaas?.redirectUrl as string,
                                    (e.key as ActivePageType) || undefined
                                  );
                                } else {
                                  setSelectedAutoSassApp(
                                    props.autoApps?.find(
                                      (item) => item._id === e.autosaas?.appId
                                    )
                                  );
                                  setIsAppSettingsModalOpen(true);
                                }
                              }}
                            />
                          )}
                      </div>
                    )
                )}
              </>
            )}
            {hasAutoSaasAccess && !subMenu && (
              <>
                <div className={s.addAppDivider} />
                <div
                  className={s.menuItem}
                  onClick={() => history.push('/console/new-app')}
                >
                  <PlusCircleIcon width={26} height={26} />
                  <span>New App</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={s.bottomItems}>
          <div
            className={clsx(s.menuItem)}
            onClick={() => handleLinkClick('/console/logout')}
          >
            <ChangePageLogoutSvg />
            <span>Log Out</span>
          </div>
          <div
            className={clsx(s.menuItem)}
            onClick={() => window.open('https://help.autofunnel.ai/')}
          >
            <ChangePageHelpSvg />
            <span>Info Center</span>
          </div>
          <div
            className={clsx(s.menuItem, {
              [s.activeItem]: activePage === 'settings',
            })}
            onClick={() => setSubMenu('settings')}
          >
            <ChangePageSettingSvg />
            <span>Settings</span>
          </div>
        </div>
      </div>
      <AutoSassAppsSettingsModal
        appData={selectedAutoSassApp}
        open={isAppSettingModalOpen}
        onClose={() => {
          setIsAppSettingsModalOpen(false);
          setSelectedAutoSassApp(undefined);
        }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  accounts: state.accounts.accounts,
  loading: state.accounts.loading,
  activeAccount: state.accounts.activeAccount,
  userAccess: state.user.userAccess,
  autosaasLogo: state.autosaas.logoUrl,
  userDetails: state.user.agencyDetails,
  autoSassAppsNames: state.autosaas.appsNames,
  autoApps: state.autosaas.autoApps,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  getAccounts: () => dispatch(fetchAccountsAction()),
  setActiveAccount: (account: IAccount | null) =>
    dispatch(setActiveAccountAction(account)),
  getAccountAccess: () => dispatch(fetchUserAccess()),
  getAutoSassApps: () => dispatch(getAutoSassApps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
