import { useCallback, useMemo } from 'react';
import { APPS, IApps, IPrice, IProduct } from 'Components/AutoSaas/constants';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as ChangePageBookSvg } from 'Assets/icons/changePageBook.svg';
import { ReactComponent as ChangePageThemeSvg } from 'Assets/icons/changePageTheme.svg';
import { ReactComponent as ChangePageWorldSvg } from 'Assets/icons/changePageWorld.svg';
import { ReactComponent as ChangePagePodcastSvg } from 'Assets/icons/changePodcast.svg';
import { ReactComponent as OutreachPageSvg } from 'Assets/icons/outreach.svg';
import { ReactComponent as DeleteIcon } from '../../../Accounts/icons/delete.svg';
import queries from 'graphql/queries';
import OptionMenu from 'Components/OptionMenu/OptionMenu';

import styles from './ProductItem.module.scss';

interface IProps {
  price: IPrice;
  handleClick: (item: IPrice) => void;
  index: number;
  onUpdateData: (item: IProduct) => void;
}

enum MenuItems {
  Delete,
}

const checkIsEnable = (key: string, data?: IApps[]): boolean => {
  return !!data?.find((app) => app.key === key)?.enabled;
};

const ProductItem = ({ price, handleClick, index, onUpdateData }: IProps) => {
  const handleDeleteItem = async () => {
    const response = await graphQlCall({
      queryTemplateObject: queries.DELETE_SAAS_PLAN_PRICE,
      values: { planIndex: 0, priceIndex: index },
      headerType: 'USER-AUTH',
    });
    onUpdateData(response?.products[0]);
  };

  const handleMenuClick = useCallback((e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.Delete: {
        handleDeleteItem();
        break;
      }
    }
  }, []);

  const menuProps = useMemo(() => {
    const menuItems = [
      {
        label: 'Delete',
        key: MenuItems.Delete,
        icon: <DeleteIcon />,
      },
    ];

    return {
      items: menuItems,
      onClick: handleMenuClick,
    };
  }, [handleMenuClick]);

  return (
    <div
      className={styles.productItem}
      onClick={() => {
        handleClick(price);
      }}
    >
      <div className={styles.productTitleContainer}>
        <div className={styles.productTitle}>{price?.name}</div>
        <div className={styles.productSubtitle}>{price?.subHeader}</div>
      </div>
      <div className={styles.productInfoContainer}>
        <div className={styles.productTypes}>
          {checkIsEnable(APPS.PAGES, price?.apps) && <ChangePageWorldSvg />}
          {checkIsEnable(APPS.BOOKLE, price?.apps) && <ChangePageBookSvg />}
          {checkIsEnable(APPS.COPYCRAFT, price?.apps) && <ChangePageThemeSvg />}
          {checkIsEnable(APPS.PODCASTER, price?.apps) && (
            <ChangePagePodcastSvg />
          )}
          {checkIsEnable(APPS.OUTREACH, price?.apps) && <OutreachPageSvg />}
        </div>
        <div className={styles.price}>
          ${price?.price! / 100}{' '}
          {/*NOTE:price we getting from backed end is in cents*/}
          <span className={styles.month}>
            {' '}
            / {price?.paymentPeriod?.slice(0, 2)}
          </span>
        </div>
        <OptionMenu menuProps={menuProps} className={styles.option} />
      </div>
    </div>
  );
};

export default ProductItem;
