import {
  IAgencyDetails,
  IUserAccess,
  IUserCustomData,
  IUserDetails,
} from 'types';
import { api, findProductByKey, getToken } from '../../utils/Utils';
import { PAGECRAFT_API_URL } from '../../Constants';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../rootReducer';
import { Action } from 'redux';
import QUERIES from '../../graphql/queries';
import { graphQlCall } from '../../graphql/utils';
import { UserInfo } from 'classes/UserInfo';

export const USER_FETCHED = 'user_fetched';
export const AGENCY_FETCHED = 'agency_fetched';
export const USER_ACCESS = 'user_access';
export const USER_GET_SUBSCRIPTIONS_DATA = 'user_get_subscriptions_data';
export const LOADING = 'USER_LOADING';
export const MAILCHIMP_INTEGRATION_SUCCESFULL =
  'MAILCHIMP_INTEGRATION_SUCCESFULL';
export const STRIPE_INTEGRATION_SUCCESFULL = 'STRIPE_INTEGRATION_SUCCESFULL';

type DispatchType = ThunkDispatch<RootState, void, Action>;
type GetStateType = () => RootState;

export const userFetched = (payload: IUserDetails) => ({
  type: USER_FETCHED,
  payload,
});

export const fetchUserDetails = () => async (dispatch: DispatchType) => {
  dispatch(loading(true));
  const user = await graphQlCall({
    queryTemplateObject: QUERIES.GET_CURRENT_USER_QUERY,
    headerType: 'USER-AUTH',
  });

  dispatch(userFetched(user as IUserDetails));
  dispatch(loading(false));
};

export const agencyFetched = (payload: IUserDetails) => ({
  type: AGENCY_FETCHED,
  payload,
});

export const fetchAgencyData = () => async (dispatch: DispatchType) => {
  const agency = await graphQlCall({
    queryTemplateObject: QUERIES.GET_CURRENT_USER_QUERY,
    headerType: 'AGENCY_AUTH',
  });

  dispatch(agencyFetched(agency as IAgencyDetails));
};

export const accessFetched = (payload: IUserAccess) => ({
  type: USER_ACCESS,
  payload,
});

export const fetchUserAccess = () => async (dispatch: DispatchType) => {
  let access = await graphQlCall({
    queryTemplateObject: QUERIES.CHECK_SUBSCRIPTION_ACCESS,
    headerType: 'USER-AUTH',
  });

  const userInfo = await UserInfo.getInstance().getInfo();

  if (!userInfo.saasId) {
    access.bookle =
      access.bookle || userInfo.scopes.includes('BOOKLE_GENERAL_ACCESS');

    access.podcast = access.funnel && access.bookle; //Temp solution to give access to podcaster to people who is having Bookle and Funnel Access.
    access.autosaas = access.saas;
  }

  if (userInfo.scopes.includes('PDCS_GENERAL_ACCESS')) {
    access.podcast = true;
  }
  if (userInfo.scopes.includes('BOOKLE_GENERAL_ACCESS')) {
    access.bookle = true;
  }
  if (userInfo.scopes.includes('FNNL_GENERAL_ACCESS')) {
    access.funnel = true;
    access.contact = true;
  }
  if (userInfo.scopes.includes('COPYCRAFT_GENERAL_ACCESS')) {
    access.copyCraft = true;
  }

  access.contact = access.funnel;

  if (userInfo.scopes.includes('SAAS_OWNER')) {
    access.funnel = true;
    access.bookle = true;
    access.copyCraft = true;
    access.contact = true;
    access.podcast = true;
    access.autosaas = true;
    access.saas = true;
  }

  dispatch(accessFetched(access as IUserAccess));
};

export const loading = (payload: boolean) => ({
  type: LOADING,
  payload,
});

export const stripeIntegrationSuccesfull = (payload: true | null | false) => ({
  type: STRIPE_INTEGRATION_SUCCESFULL,
  payload,
});

export const updateUserBilling = (
  stripePublicKey: string,
  stripeSecretKey: string
) => async (dispatch: DispatchType, getState: GetStateType) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_USER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        stripePublicKey,
        stripeSecretKey,
      },
    });
    const state = getState();
    const user = { ...state.user.details, stripePublicKey, stripeSecretKey };
    dispatch(userFetched(user as IUserDetails));
    dispatch(stripeIntegrationSuccesfull(true));
    setTimeout(() => {
      dispatch(stripeIntegrationSuccesfull(null));
    }, 3000);
  } catch (error: any) {
    console.log(error.message);
    dispatch(stripeIntegrationSuccesfull(false));
  }
  dispatch(loading(false));
};

export const mailchimpIntegrationSuccesfull = (
  payload: true | null | false
) => ({
  type: MAILCHIMP_INTEGRATION_SUCCESFULL,
  payload,
});

export const updateUserCustomData = (field: string, value: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    dispatch(loading(true));
    const state = getState();
    const customData = { ...state.user.details.customData };
    customData[field as keyof IUserCustomData] = value;

    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_USER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        customData: JSON.stringify(customData),
      },
    });

    const user = { ...state.user.details };
    user.customData = customData;
    dispatch(userFetched(user as IUserDetails));
    dispatch(mailchimpIntegrationSuccesfull(true));
    setTimeout(() => {
      dispatch(mailchimpIntegrationSuccesfull(null));
    }, 3000);
  } catch (error: any) {
    console.log(error.message);
    dispatch(mailchimpIntegrationSuccesfull(false));
  }
  dispatch(loading(false));
};

export const fetchUserCustomData = () => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const updatedUser = await graphQlCall({
    queryTemplateObject: QUERIES.GET_CURRENT_USER_QUERY,
    headerType: 'USER-AUTH',
  });
  const state = getState();
  const user = { ...state.user.details, ...updatedUser };
  user.customData = updatedUser.customData || {};
  dispatch(userFetched(user as IUserDetails));
};

export const fetchUserSubscriptionPlanDataAction = () => (
  dispatch: DispatchType
) => {
  const headers = {
    authorization: getToken(),
  };
  api(`${PAGECRAFT_API_URL}/users/subscriptions`, 'GET', null, headers).then(
    (response) => {
      let planData = null;
      response.userSubscriptions[0].items.data.forEach((subscription: any) => {
        const planType = findProductByKey(subscription.plan.id);
        const isActivePlan = subscription.plan.active;
        if (planType && isActivePlan) {
          planData = {
            price: Math.round(subscription.plan.amount / 100),
            planType: planType,
            planInterval: subscription.plan.interval,
          };
        }
      });
      if (planData) {
        const payload = planData;
        dispatch({ type: USER_GET_SUBSCRIPTIONS_DATA, payload });
      }
    }
  );
};

export const userDeleteAction = () => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const headers = {
    authorization: getToken(),
  };
  api(`${PAGECRAFT_API_URL}/users/remove-user`, 'DELETE', null, headers).then(
    () => {
      localStorage.clear();
      const url = `/console/login`;
      window.open(url, '_self');
    }
  );
};
