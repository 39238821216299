export const UPDATE_GENERATION_TEMPLATE_MUTATION = {
  operationName: 'updateGenerationTemplate',
  query: `mutation updateGenerationTemplate(
  <KEYS>
        ) {
          updateGenerationTemplate(
          <VALUES>
          ) {
              _id
              name
              layout
              createdAt
              updatedAt
              actions {
                id
                type
                forms {
                  type
                  name
                  variables {
                    id
                    type
                    label
                  }
                }
                metadata
                prompt
                postFunctions
              }
            }
          }`,
};
