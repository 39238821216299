import { ReactComponent as ChangePageBookSvg } from 'Assets/icons/changePageBook.svg';
import { ReactComponent as ChangePageThemeSvg } from 'Assets/icons/changePageTheme.svg';
import { ReactComponent as ChangePageWorldSvg } from 'Assets/icons/changePageWorld.svg';
import { ReactComponent as ChangePagePodcastSvg } from 'Assets/icons/changePodcast.svg';
import { ReactComponent as OutreachPageSvg } from 'Assets/icons/outreach.svg';

interface DefaultColors {
  default: string;
  [key: string]: any;
}

export interface ColorScheme {
  accent?: string;
  alerts?: string;
  sideBar?: string;
  [key: string]: any;
}

interface Item {
  key: string;
  defaultColors: DefaultColors;
  title: string;
  subtitle: string;
}

export enum APPS {
  PAGES = 'PAGES',
  BOOKLE = 'BOOKLE',
  COPYCRAFT = 'COPYCRAFT',
  PODCASTER = 'PODCASTER',
  OUTREACH = 'OUTREACH',
}

export interface Section {
  key: string;
  title: string;
  subtitle: string;
  items: Item[];
}

export interface IApps {
  key: string;
  name: string;
  enabled: boolean;
  items?: {
    option: string;
    limit: string;
  }[];
}

export interface IPrice {
  stripeId?: string;
  name?: string;
  subHeader?: string;
  description?: string;
  price?: number;
  trial?: number;
  paymentPeriod?: string;
  apps?: IApps[];
}

export interface IProduct {
  name: string;
  prices: IPrice[];
}

export const defaultColorSchemes = [
  {
    value: 'default',
    label: 'Default',
    colors: ['#000000', '#4957D8', '#FFFFFF', '#474747'],
  },
  {
    value: 'slateTwilight',
    label: 'Slate Twilight',
    colors: ['#233451', '#01AE82', '#FFFFFF', '#A5C2FF'],
  },
  {
    value: 'rustEmber',
    label: 'Rust Ember',
    colors: ['#402822', '#E45B00', '#FFFFFF', '#594338'],
  },
  {
    value: 'royalNight',
    label: 'Royal Night',
    colors: ['#251143', '#45B400', '#FFFFFF', '#36285c'],
  },
  {
    value: 'plumShadow',
    label: 'Plum Shadow',
    colors: ['#520E2B', '#0CB400', '#FFFFFF', '#582849'],
  },
  {
    value: 'neonFern',
    label: 'Neon Fern',
    colors: ['#0B411A', '#0CB400', '#FFFFFF', '#2f4f3d'],
  },
  {
    value: 'cobaltAbyss',
    label: 'Cobalt Abyss',
    colors: ['#0B3441', '#006FB4', '#FFFFFF', '#10495B'],
  },
  {
    value: 'monoDark',
    label: 'Mono Dark',
    colors: ['#000000', '#000000', '#FFFFFF', '#2C2C2C'],
  },
  {
    value: 'periwinkleSky',
    label: 'Periwinkle Sky',
    colors: ['#FFFFFF', '#4957D8', '#000000', '#EDEDED'],
  },
  {
    value: 'aquaBreeze',
    label: 'Aqua Breeze',
    colors: ['#F4FCFD', '#00ABB4', '#000000', '#DDEEF0'],
  },
  {
    value: 'coralGlow',
    label: 'Coral Glow',
    colors: ['#FFF1E9', '#E56953', '#000000', '#F5DCCD'],
  },
  {
    value: 'lavenderGlow',
    label: 'Lavender Glow',
    colors: ['#E3E3E3', '#6463B3', '#000000', '#EFEFEF'],
  },
  {
    value: 'pinkBlossom',
    label: 'Pink Blossom',
    colors: ['#FFECF9', '#E55395', '#000000', '#F8E0F2'],
  },
  {
    value: 'mintSplash',
    label: 'Mint Splash',
    colors: ['#F9F9F9', '#51C097', '#000000', '#E2EAE7'],
  },
  {
    value: 'ceruleanSky',
    label: 'Cerulean Sky',
    colors: ['#FFFFFF', '#0069B4', '#000000', '#EDF0F2'],
  },
  {
    value: 'monoBright',
    label: 'Mono Bright',
    colors: ['#FFFFFF', '#000000', '#FFFFFF', '#EDEDED'],
  },
];

export const advancedSchemeDefaultData: Section[] = [
  //   {
  //     key: 'userInterface',
  //     title: 'User Interface',
  //     subtitle: 'For dark and bright elements',
  //     items: [
  //       {
  //         key: 'masterBackground',
  //         title: 'Master Black',
  //         subtitle: 'Primarily background, etc.',
  //         defaultColors: {
  //           default: '#000000',
  //         },
  //       },
  //       {
  //         key: 'secondaryBackground',
  //         title: 'Secondary Background',
  //         subtitle: 'Secondary background, some decor elements',
  //         defaultColors: {
  //           default: '#1F1F1F',
  //         },
  //       },
  //       {
  //         key: 'hover',
  //         title: 'Hover',
  //         subtitle: 'Hover State for Black elements, etc.',
  //         defaultColors: {
  //           default: '#2C2C2C',
  //         },
  //       },
  //       {
  //         key: 'button',
  //         title: 'Button on Black Background',
  //         subtitle: 'Primarily button on the black background',
  //         defaultColors: {
  //           default: '#3E3E3E',
  //         },
  //       },
  //       {
  //         key: 'strokeButton',
  //         title: 'Stroke Button on Black Background',
  //         subtitle: 'Stroke of secondary button, etc.',
  //         defaultColors: {
  //           default: '#5A5A5A',
  //         },
  //       },
  //       {
  //         key: 'secondaryText',
  //         title: 'Secondary Text',
  //         subtitle: 'Subheadline, recomendations, hint text, etc.',
  //         defaultColors: {
  //           default: '#7D7C7C',
  //         },
  //       },
  //       {
  //         key: 'fieldStroke',
  //         title: 'Field Stroke',
  //         subtitle: 'Field stroke color, placeholder, etc.',
  //         defaultColors: {
  //           default: '#A0A0A0',
  //         },
  //       },
  //       {
  //         key: 'text',
  //         title: 'Text on Black Background',
  //         subtitle: 'Hover Over State If it’s necessary, etc.',
  //         defaultColors: {
  //           default: '#D0D0D0',
  //         },
  //       },
  //       {
  //         key: 'secondaryThings',
  //         title: 'Secondary Things',
  //         subtitle: 'Soundwave, etc.',
  //         defaultColors: {
  //           default: '#E8E8E8',
  //         },
  //       },
  //       {
  //         key: 'menuItemHover',
  //         title: 'Menu Item Hover',
  //         subtitle: 'Hover State of dropdown menu items etc.',
  //         defaultColors: {
  //           default: '#F0F0F0',
  //         },
  //       },
  //       {
  //         key: 'blocksBackground',
  //         title: 'Blocks Background',
  //         subtitle: 'Background of the floating blocks',
  //         defaultColors: {
  //           default: '#FAFAFA',
  //         },
  //       },
  //       {
  //         key: 'whiteAccent',
  //         title: 'White Accent',
  //         subtitle: 'Hover state of blocks & text no dark background',
  //         defaultColors: {
  //           default: '#FFFFFF',
  //         },
  //       },
  //     ],
  //   },
  {
    key: 'sideBar',
    title: 'Sidebar',
    subtitle: 'Separated from other interface',
    items: [
      {
        key: 'background',
        title: 'Background',
        subtitle: 'Sidebar Background',
        defaultColors: {
          default: '#000000',
          slateTwilight: '#233451',
          rustEmber: '#402822',
          royalNight: '#251143',
          plumShadow: '#520E2B',
          neonFern: '#0B411A',
          cobaltAbyss: '#0B3441',
          monoDark: '#000000',
          periwinkleSky: '#FFFFFF',
          aquaBreeze: '#F4FCFD',
          coralGlow: '#FFF1E9',
          lavenderGlow: '#E3E3E3',
          pinkBlossom: '#FFECF9',
          mintSplash: '#F9F9F9',
          ceruleanSky: '#FFFFFF',
          monoBright: '#FFFFFF',
        },
      },
      {
        key: 'appHover',
        title: 'App Hover',
        subtitle: 'Apps and Settings Background',
        defaultColors: {
          default: '#2C2C2C',
          slateTwilight: '#37496B',
          rustEmber: '#5D4236',
          royalNight: '#39275F',
          plumShadow: '#5F254B',
          neonFern: '#23503C',
          cobaltAbyss: '#10495B',
          monoDark: '#2C2C2C',
          periwinkleSky: '#EDEDED',
          aquaBreeze: '#DDEEF0',
          coralGlow: '#F5DCCD',
          lavenderGlow: '#EFEFEF',
          pinkBlossom: '#F8E0F2',
          mintSplash: '#E2EAE7',
          ceruleanSky: '#EDF0F2',
          monoBright: '#EDEDED',
        },
      },
      {
        key: 'appSelected',
        title: 'App Selected',
        subtitle: 'Apps Background',
        defaultColors: {
          default: '#2F3882',
          slateTwilight: '#01684E',
          rustEmber: '#893700',
          royalNight: '#296C00',
          plumShadow: '#076C00',
          neonFern: '#076C00',
          cobaltAbyss: '#006FB4',
          monoDark: '#525252',
          periwinkleSky: '#4957D8',
          aquaBreeze: '#00ABB4',
          coralGlow: '#E56953',
          lavenderGlow: '#7F7DFF',
          pinkBlossom: '#E55395',
          mintSplash: '#62C8A3',
          ceruleanSky: '#2680BF',
          monoBright: '#000000',
        },
      },
      {
        key: 'divider',
        title: 'Divider',
        subtitle: 'Line at the top and bottom',
        defaultColors: {
          default: '#3E3E3E',
          slateTwilight: '#4A5F85',
          rustEmber: '#5D4236',
          royalNight: '#39275F',
          plumShadow: '#6D3B6B',
          neonFern: '#2A673C',
          cobaltAbyss: '#2A5867',
          monoDark: '#4D4D4D',
          periwinkleSky: '#DEDEDE',
          aquaBreeze: '#ACC5C8',
          coralGlow: '#DBB198',
          lavenderGlow: '#A9A9A9',
          pinkBlossom: '#ECCAE1',
          mintSplash: '#A9A9A9',
          ceruleanSky: '#DEDEDE',
          monoBright: '#DEDEDE',
        },
      },
      {
        key: 'text',
        title: 'Text and Icons',
        subtitle: 'Normal State',
        defaultColors: {
          default: '#D0D0D0',
          slateTwilight: '#BDC2CB',
          rustEmber: '#C6BEBD',
          royalNight: '#BEB8C7',
          plumShadow: '#CBB7BF',
          neonFern: '#B6C6BA',
          cobaltAbyss: '#B6C2C6',
          monoDark: '#B2B2B2',
          periwinkleSky: '#4D4D4D',
          aquaBreeze: '#587376',
          coralGlow: '#915F56',
          lavenderGlow: '#505050',
          pinkBlossom: '#915687',
          mintSplash: '#505050',
          ceruleanSky: '#4D4D4D',
          monoBright: '#4D4D4D',
        },
      },
      {
        key: 'selectedText',
        title: 'Text and Icons Highlighted',
        subtitle: 'Selected and Hover States',
        defaultColors: {
          default: '#FFFFFF',
          slateTwilight: '#FFFFFF',
          rustEmber: '#FFFFFF',
          royalNight: '#FFFFFF',
          plumShadow: '#FFFFFF',
          neonFern: '#FFFFFF',
          cobaltAbyss: '#FFFFFF',
          monoDark: '#FFFFFF',
          periwinkleSky: '#FFFFFF',
          aquaBreeze: '#000000',
          coralGlow: '#000000',
          lavenderGlow: '#000000',
          pinkBlossom: '#000000',
          mintSplash: '#000000',
          ceruleanSky: '#000000',
          monoBright: '#000000',
        },
      },
    ],
  },
  {
    key: 'accent',
    title: 'Accent',
    subtitle: 'The colors of the things that need to be emphasized',
    items: [
      {
        key: 'accent',
        title: 'Accent',
        subtitle: 'Elements which need to highlight, etc.',
        defaultColors: {
          default: '#4957D8',
          slateTwilight: '#01AE82',
          rustEmber: '#E45B00',
          royalNight: '#45B400',
          plumShadow: '#0CB400',
          neonFern: '#0CB400',
          cobaltAbyss: '#006FB4',
          monoDark: '#000000',
          periwinkleSky: '#4957D8',
          aquaBreeze: '#00ABB4',
          coralGlow: '#E56953',
          lavenderGlow: '#6463B3',
          pinkBlossom: '#E55395',
          mintSplash: '#51C097',
          ceruleanSky: '#0069B4',
          monoBright: '#000000',
        },
      },
      {
        key: 'accentHover',
        title: 'Accent Hover',
        subtitle: 'Hover Over State If it’s necessary, etc.',
        defaultColors: {
          default: '#6674F4',
          slateTwilight: '#5bb797',
          rustEmber: '#d97a3b',
          royalNight: '#79bd44',
          plumShadow: '#60bc43',
          neonFern: '#60bc43',
          cobaltAbyss: '#5683ba',
          monoDark: '#2C2C2C',
          periwinkleSky: '#6670d7',
          aquaBreeze: '#5ab5c7',
          coralGlow: '#db8572',
          lavenderGlow: '#7b7ab9',
          pinkBlossom: '#d974a3',
          mintSplash: '#82c7a9',
          ceruleanSky: '#427eba',
          monoBright: '#4B4B4B',
        },
      },
      {
        key: 'darkAccent',
        title: 'Dark Accent',
        subtitle: 'Accent for Dark Background, etc.',
        defaultColors: {
          default: '#252C68',
          slateTwilight: '#01684E',
          rustEmber: '#7f3c14',
          royalNight: '#3c6b1b',
          plumShadow: '#2f6a1b',
          neonFern: '#2f6a1b',
          cobaltAbyss: '#006FB4',
          monoDark: '#525252',
          periwinkleSky: '#2e347d',
          aquaBreeze: '#2b656b',
          coralGlow: '#804336',
          lavenderGlow: '#3c3b68',
          pinkBlossom: '#7f3858',
          mintSplash: '#43755d',
          ceruleanSky: '#173e69',
          monoBright: '#4A4A4A',
        },
      },
      {
        key: 'darkAccentHover',
        title: 'Dark Accent Hover',
        subtitle: 'Hover Over State If it’s necessary, etc.',
        defaultColors: {
          default: '#2F3882',
          slateTwilight: '#34785d',
          rustEmber: '#944619',
          royalNight: '#467c21',
          plumShadow: '#387c21',
          neonFern: '#387c21',
          cobaltAbyss: '#007FCE',
          monoDark: '#6F6F6F',
          periwinkleSky: '#353d92',
          aquaBreeze: '#33767d',
          coralGlow: '#954f3f',
          lavenderGlow: '#464579',
          pinkBlossom: '#944167',
          mintSplash: '#4e846c',
          ceruleanSky: '#1d497a',
          monoBright: '#727272',
        },
      },
    ],
  },
  {
    key: 'alerts',
    title: 'Alerts',
    subtitle: 'States and alerts',
    items: [
      {
        key: 'success',
        title: 'Success',
        subtitle: 'Successful experience, Something turned on, etc.',
        defaultColors: {
          default: '#25BB73',
          slateTwilight: '#25BBB1',
          rustEmber: '#25BB73',
          royalNight: '#855FD6',
          plumShadow: '#25BB73',
          neonFern: '#25BB73',
          cobaltAbyss: '#25BB73',
          monoDark: '#25BB73',
          periwinkleSky: '#25BB73',
          aquaBreeze: '#25BB73',
          coralGlow: '#25BB73',
          lavenderGlow: '#25BB73',
          pinkBlossom: '#25BB73',
          mintSplash: '#25BB73',
          ceruleanSky: '#25BB73',
          monoBright: '#25BB73',
        },
      },
      {
        key: 'successHover',
        title: 'Success Hover',
        subtitle: 'Hover Over State If it’s necessary',
        defaultColors: {
          default: '#18DA7D',
          slateTwilight: '#2FD9CE',
          rustEmber: '#18DA7D',
          royalNight: '#9D74F5',
          plumShadow: '#18DA7D',
          neonFern: '#18DA7D',
          cobaltAbyss: '#18DA7D',
          monoDark: '#18DA7D',
          periwinkleSky: '#18DA7D',
          aquaBreeze: '#18DA7D',
          coralGlow: '#18DA7D',
          lavenderGlow: '#18DA7D',
          pinkBlossom: '#18DA7D',
          mintSplash: '#18DA7D',
          ceruleanSky: '#18DA7D',
          monoBright: '#18DA7D',
        },
      },
      {
        key: 'error',
        title: 'Error',
        subtitle: 'Alarms, Attintions etc.',
        defaultColors: {
          default: '#FF4F4F',
          slateTwilight: '#FF4F4F',
          rustEmber: '#FF4F4F',
          royalNight: '#FF4F4F',
          plumShadow: '#FF4F4F',
          neonFern: '#FF4F4F',
          cobaltAbyss: '#FF4F4F',
          monoDark: '#FF4F4F',
          periwinkleSky: '#FF4F4F',
          aquaBreeze: '#FF4F4F',
          coralGlow: '#FF4F4F',
          lavenderGlow: '#FF4F4F',
          pinkBlossom: '#FF4F4F',
          mintSplash: '#FF4F4F',
          ceruleanSky: '#FF4F4F',
          monoBright: '#FF4F4F',
        },
      },
      {
        key: 'waiting',
        title: 'Waiting',
        subtitle: 'Moderation, waiting mod, etc.',
        defaultColors: {
          default: '#FAA500',
          slateTwilight: '#FAA500',
          rustEmber: '#FAA500',
          royalNight: '#FAA500',
          plumShadow: '#FAA500',
          neonFern: '#FAA500',
          cobaltAbyss: '#FAA500',
          monoDark: '#FAA500',
          periwinkleSky: '#FAA500',
          aquaBreeze: '#FAA500',
          coralGlow: '#FAA500',
          lavenderGlow: '#FAA500',
          pinkBlossom: '#FAA500',
          mintSplash: '#FAA500',
          ceruleanSky: '#FAA500',
          monoBright: '#FAA500',
        },
      },
    ],
  },
];

export const applications = [
  {
    key: APPS.PAGES,
    name: 'Pages',

    selects: [
      {
        key: 'funnels',
        label: 'Funnels',
        options: [
          { value: '5', label: '5' },
          { value: '20', label: '20' },
          { value: '100', label: '100' },
          { value: '300', label: '300' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
    ],

    icon: <ChangePageWorldSvg />,
  },
  {
    key: APPS.BOOKLE,
    name: 'Bookle',
    selects: [
      {
        key: 'books',
        label: 'Books',
        options: [
          { value: '5', label: '5' },
          { value: '20', label: '20' },
          { value: '100', label: '100' },
          { value: '300', label: '300' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
      {
        key: 'booksSize',
        label: 'Books Size',
        options: [
          { value: 'small', label: 'Small' },
          { value: 'small_medium', label: 'Small+Medium' },
          { value: 'small_medium_large', label: 'Small+Medium+Large' },
        ],
      },
    ],
    icon: <ChangePageBookSvg />,
  },
  {
    key: APPS.COPYCRAFT,
    name: 'CopyCraft',
    selects: [
      {
        key: 'requests',
        label: 'Requests',
        options: [
          { value: '100', label: '100' },
          { value: '500', label: '500' },
          { value: '1000', label: '1000' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
    ],
    icon: <ChangePageThemeSvg />,
  },
  {
    key: APPS.PODCASTER,
    name: 'Podcaster',
    selects: [
      {
        key: 'PODCASTS',
        label: 'Podcasts',
        options: [
          { value: '5', label: '5' },
          { value: '20', label: '20' },
          { value: '100', label: '100' },
          { value: '300', label: '300' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
      {
        key: 'episodePerPodcast',
        label: 'Episodes per Podcast',
        options: [
          { value: '10', label: '10' },
          { value: '30', label: '30' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
    ],
    icon: <ChangePagePodcastSvg />,
  },
  {
    key: APPS.OUTREACH,
    name: 'Messaging',
    selects: [
      {
        key: 'broadcasts',
        label: 'Broadcasts',
        options: [
          { value: '20', label: '20' },
          { value: '100', label: '100' },
          { value: '300', label: '300' },
          { value: '1000', label: '1000' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
      {
        key: 'contactsSend',
        label: 'Contacts send',
        options: [
          { value: '1000', label: '1000' },
          { value: '5000', label: '5000' },
          { value: '10000', label: '10 000' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
      {
        key: 'workflows',
        label: 'Workflows',
        options: [
          { value: '5', label: '5' },
          { value: '20', label: '20' },
          { value: '100', label: '100' },
          { value: '300', label: '300' },
          { value: 'unlimited', label: 'Unlimited' },
        ],
      },
    ],
    icon: <OutreachPageSvg />,
  },
];
