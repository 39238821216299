import { useEffect, useState } from 'react';
import { SmartList, Tag } from 'Pages/PageContacts/Helper/types';
import { ReactComponent as Close } from 'Assets/icons/close.svg';
import clsx from 'clsx';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import RadioButton from 'UILib/RadioButton/RadioButton';

import styles from './ContactsDetails.module.scss';

export enum addToOptionsEnum {
  ALL = 'all',
  NEW_SMART_LIST = 'newSmartList',
  EXISTING_SMART_LIST = 'existingSmartList',
}

export enum whatDoYouWantEnum {
  ADD_NEW = 'addNew',
  ADD_NEW_AND_UPDATE = 'addNewAndUpdate',
}

const addToOptions = [
  {
    key: 1,
    label: 'All',
    name: addToOptionsEnum.ALL,
  },
  {
    key: 2,
    label: 'New Smart List',
    name: addToOptionsEnum.NEW_SMART_LIST,
  },
  {
    key: 3,
    label: 'Existing Smart List',
    name: addToOptionsEnum.EXISTING_SMART_LIST,
  },
];

const whatDoYOuWantOptions = [
  {
    key: 1,
    label: 'Add New and Update Existing Contacts',
    subLabel:
      'Contacts that do not exist will be added, and existing ones will be updated.',
    name: whatDoYouWantEnum.ADD_NEW_AND_UPDATE,
  },
  {
    key: 2,
    label: 'Add Only New Contacts',
    subLabel: 'Contacts that already exist will be duplicated.',
    name: whatDoYouWantEnum.ADD_NEW,
  },
];

interface IProps {
  onChangeContactOption: (option: string) => void;
  contactOption: string;
  whatYouWantOption: string;
  onChangeWantOption: (option: string) => void;
  tags: Tag[];
  smartLists: SmartList[];
  selectedTags: Tag[];
  handleAddTag: (tag: Tag) => void;
  handleRemoveTag: (id: string) => void;
  onChangeSmartListName: (name: string) => void;
  smartListName: string;
  smartListId: string;
  setSmartListId: (id: string) => void;
  errors: { [key: string]: string };
  handleAddNewTag: (tagName: string) => void;
}

const ContactsDetails = ({
  onChangeContactOption,
  contactOption,
  whatYouWantOption,
  onChangeWantOption,
  tags,
  smartLists,
  selectedTags,
  handleAddTag,
  handleRemoveTag,
  onChangeSmartListName,
  smartListName,
  smartListId,
  setSmartListId,
  handleAddNewTag,
  errors,
}: IProps) => {
  const [newTagName, setNewTagName] = useState<string>('');
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);

  const addNewTag = () => {
    handleAddNewTag(newTagName);
    setNewTagName('');
  };

  useEffect(() => {
    if (tags.length) {
      setFilteredTags(tags.filter((item) => item.name.includes(newTagName)));
    }
  }, [tags, newTagName]);

  return (
    <div className={styles.container}>
      <div>
        {/* <div className={styles.title}>Add Contact To</div>
        <div className={styles.radioButtons}>
          {addToOptions.map((item) => (
            <RadioButton
              key={item.key}
              label={item.label}
              name={item.name}
              value={item.name}
              checked={contactOption === item.name}
              onChange={onChangeContactOption}
            />
          ))}
          {(contactOption === addToOptionsEnum.EXISTING_SMART_LIST ||
            contactOption === addToOptionsEnum.NEW_SMART_LIST) && (
            <div>Smart List Name</div>
          )}
          {contactOption === addToOptionsEnum.NEW_SMART_LIST && (
            <Input
              border="stroke"
              className={styles.smartListInput}
              placeholder="List Name"
              onChange={(e) => onChangeSmartListName(e.target.value)}
              value={smartListName}
              error={errors?.smartListName}
            />
          )}
          {contactOption === addToOptionsEnum.EXISTING_SMART_LIST && (
            <div>
              <Dropdown
                label={
                  smartLists.find((item) => item._id === smartListId)?.name ||
                  'Select'
                }
                options={smartLists.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                onChange={(value) => setSmartListId(value)}
                type="stroke"
                className={styles.dropdown}
                hasErrors={!!errors?.smartList}
              />
              <div className={styles.errorMessage}>{errors?.smartList}</div>
            </div>
          )}
        </div> */}
        <div className={styles.contentContainer}>
          <div className={styles.title}>
            What do you want to do with Contacts?
          </div>
          <div className={styles.radioButtons}>
            {whatDoYOuWantOptions.map((item) => (
              <RadioButton
                key={item.key}
                name={item.name}
                value={item.name}
                checked={whatYouWantOption === item.name}
                onChange={onChangeWantOption}
                label={
                  <span className={styles.radioLabel}>
                    <span>{item.label}</span>
                    <span className={styles.subLabel}>{item.subLabel}</span>
                  </span>
                }
              />
            ))}
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.title}>Assign Tags</div>
          <div className={styles.tagsContainer}>
            <div>
              <Dropdown
                hasErrors={!!errors?.tags}
                options={[
                  {
                    value: 'add-new-tag',
                    label: (
                      <div
                        className={styles.addTagContainer}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <Input
                          border="stroke"
                          className={styles.attTagInput}
                          onChange={(e) => setNewTagName(e.target.value)}
                          value={newTagName}
                          placeholder="search..."
                        />
                        {newTagName &&
                          !filteredTags.find((e) => e.name === newTagName) && (
                            <Button
                              className={styles.addTagButton}
                              onClick={addNewTag}
                            >
                              Add +
                            </Button>
                          )}
                      </div>
                    ),
                  },
                  ...filteredTags.map((item) => {
                    const isSelected = !!selectedTags.find(
                      (e) => e._id === item._id
                    );
                    return {
                      label: (
                        <div
                          className={clsx(styles.optionLabel, {
                            [styles.disabledOption]: isSelected,
                          })}
                        >
                          {item.name}
                        </div>
                      ),
                      value: item._id,
                    };
                  }),
                ]}
                label="Select Tags"
                placeholder="Select Tags"
                onChange={(newValue) => {
                  const tag = filteredTags.find(
                    (item) => item._id === newValue
                  );
                  if (tag) {
                    handleAddTag(tag);
                  }
                }}
                type="stroke"
                className={styles.dropdown}
              />
              <div className={styles.errorMessage}>{errors?.tags}</div>
            </div>
            <div className={styles.tags}>
              {selectedTags.map((item) => (
                <div className={styles.tag}>
                  {item.name}
                  <Close
                    className={styles.removeIcon}
                    onClick={() => handleRemoveTag(item._id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsDetails;
