import { useState } from 'react';
import { graphQlCall } from 'graphql/utils';
import { IAccount } from 'Components/Accounts/types';
import { IDuplicatePageOtherAccountPayload } from 'types';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import WordIcon from 'Assets/icons/worldBlack.svg';
import QUERIES from 'graphql/queries';

import styles from './DuplicatePageOtherAccountPopup.module.scss';

interface IProps {
  owner: string;
  pageId: string;
  open: boolean;
  accounts: IAccount[];
  onClose: () => void;
  onDuplicate: (payload: IDuplicatePageOtherAccountPayload) => void;
}

interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const DuplicatePageOtherAccountPopup = (props: IProps) => {
  const [selectedAccountSub, setSelectedAccountSub] = useState('');
  const [selectedFunnelId, setSelectedFunnelId] = useState('');
  const [funnelsOptions, setFunnelsOptions] = useState<ISelectOption[]>([]);

  const accountOptions: ISelectOption[] = props.accounts.map((account) => ({
    label: account.workspace ? account.workspace : account.name,
    value: account.owner,
  }));

  const handleSelectAccount = async (sub: string) => {
    setFunnelsOptions([]);
    const funnels = await graphQlCall({
      queryTemplateObject: QUERIES.GET_FUNNELS_FROM_SUB_ACCOUNT,
      values: {
        sub,
      },
      headerType: 'AGENCY_AUTH',
    });

    if (!funnels.length) {
      setFunnelsOptions([]);
    } else {
      const options: ISelectOption[] = [];
      funnels.forEach((funnel: any) => {
        options.push({
          value: funnel._id,
          label: funnel.name,
        });
      });
      setFunnelsOptions(options);
    }
    setSelectedAccountSub(sub);
  };

  const processDuplicatePage = async () => {
    if (!!selectedFunnelId) {
      props.onDuplicate({
        targetAccountOwner: selectedAccountSub,
        sourceAccountOwner: props.owner,
        funnelId: selectedFunnelId,
        pageId: props.pageId,
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedAccountSub('');
    setSelectedFunnelId('');
    setFunnelsOptions([]);
    props.onClose();
  };

  if (!props.open) {
    return null;
  }

  return (
    <Popup onClose={handleClose} wrapperClassName={styles.container}>
      <div className={styles.modal}>
        <img src={WordIcon} alt="" className={styles.mainImage} />
        <h2 className={styles.header}>Copy to Another Funnel</h2>
        <p className={styles.additionalText}>
          Choose the funnel you want to copy this page
        </p>
        <Dropdown
          options={accountOptions}
          onChange={(account: string) => handleSelectAccount(account)}
          value={selectedAccountSub}
          label="Select Account"
          type="stroke"
          className={styles.dropdown}
        />
        <Dropdown
          options={funnelsOptions}
          onChange={(funnelId: string) => setSelectedFunnelId(funnelId)}
          value={selectedFunnelId}
          label="Select funnel"
          type="stroke"
          className={styles.dropdown}
        />
        <div className={styles.buttonsBlock}>
          <Button
            onClick={() => handleClose()}
            className={styles.button}
            appearance="stroke"
          >
            Cancel
          </Button>
          <Button
            onClick={() => processDuplicatePage()}
            disabled={selectedFunnelId === '0'}
            className={styles.button}
          >
            Copy
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default DuplicatePageOtherAccountPopup;
