import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import Link from 'UILib/Link/Link';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import PillTab from 'UILib/PillTab/PillTab';
import TextArea from 'UILib/TextArea/TextArea';
import Loader from 'UILib/Loader/Loader';
import queries from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import { MenuItems } from 'Pages/PageBookleTemplateEdior/Draggable/Sidebar/Sidebar';
import { generateNewIndex } from 'Pages/PageBookleTemplateEdior/Draggable/utils';
import { ReactComponent as MagicIcon } from 'Assets/icons/magic.svg';
import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as ReloadIcon } from 'Assets/icons/reload.svg';
import { ReactComponent as ResizeIcon } from 'Assets/icons/resize.svg';
import { createSocket, getToken, getUserId } from '../../utils/Utils';
import {
  Block,
  defaultTemplateActionSet,
  ParsedAction,
  Prompt,
  prompts,
} from './types';

import styles from './NewApp.module.scss';

const getRandomPrompts = (prompts: Prompt[], count: number) => {
  const shuffled = [...prompts].sort(() => Math.random() - 0.5);
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled.slice(0, count);
};

const NewApp = () => {
  const [selectedTab, setSelectedTab] = useState<string | number>('');
  const [appName, setAppName] = useState<string>('');
  const [appDescription, setAppDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayedPrompts, setDisplayedPrompts] = useState<Prompt[]>([]);
  const [loadingPrompts, setLoadingPrompts] = useState<boolean>(false);
  const [isAIGenerating, setIsAIGenerating] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const percentRef = useRef<number>(0);
  const intervalId = useRef<NodeJS.Timeout | number | null>(null);

  const history = useHistory();

  useEffect(() => {
    setDisplayedPrompts(getRandomPrompts(prompts, 3));
  }, []);

  const handleReloadPrompts = () => {
    setLoadingPrompts(true);
    setTimeout(() => {
      setLoadingPrompts(false);
      setDisplayedPrompts(getRandomPrompts(prompts, 3));
    }, 1000);
  };

  const handleSelectPrompt = (prompt: Prompt) => {
    setSelectedTab(prompt.prompt);
    setAppName(prompt.name);
    setAppDescription(prompt.description);
  };

  const handleClearInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    setProgress(0);
  };

  const imitateProgressBar = () => {
    percentRef.current = 0;
    setProgress(0);
    intervalId.current = setInterval(() => {
      if (percentRef.current < 100) {
        percentRef.current += 1;
        setProgress(percentRef.current);
      }
    }, 250);
  };

  const handleGenerate = async () => {
    try {
      if (!!appName && !!appDescription && !isLoading && !isAIGenerating) {
        imitateProgressBar();
        const socket = createSocket();
        setIsAIGenerating(true);

        const timeout = setTimeout(() => {
          socket.off('ai-autoapp-generate-response');
          setIsAIGenerating(false);
          handleClearInterval();
          notification.error({
            message: 'No response received within. Please try again.',
            placement: 'topRight',
            duration: 1.5,
          });
        }, 220000);

        socket.emit('ai-autoapp-generate', {
          name: appName,
          prompt: appDescription,
          userId: getUserId(),
          token: getToken(),
        });

        socket.on('ai-autoapp-generate-response', async (res) => {
          if (res.error) {
            notification.error({
              message: res.error,
              placement: 'topRight',
              duration: 1.5,
            });
          }
          clearTimeout(timeout);
          await handleCreateAutoApp(res.name, res.actions, true);
        });
      }
    } catch (error) {
      console.error(error);
      handleClearInterval();
    }
  };

  const handleCreateAutoApp = async (
    name: string,
    actions: string,
    isAI?: boolean
  ) => {
    //TODO: workaround when GPT returns not correct JSON but wrapped with ```json{...}```.
    if (actions.search('```json') === 0) {
      actions = actions.substring(7, actions.length); //remove first 7 characters
      actions = actions.substring(0, actions.length - 3); //remove last 3 characters
    }

    const parsedActions: ParsedAction = JSON.parse(actions);

    let blocks: Block[] = [];

    if (isAI) {
      blocks = [
        ...parsedActions?.postFunctions?.map((e) => ({
          id: generateNewIndex(),
          //TODO: need to remove hardcoded 'each' operator
          type: e?.each?.type?.includes('Image')
            ? MenuItems.IMAGE_BLOCK
            : MenuItems.TEXT_BLOCK,
          variable: e?.each?.variable,
          nodeId: e?.each?.id,
          text: [
            {
              type: 'paragraph',
              children: [{ text: e?.each?.prompt }],
            },
          ],
        })),
      ];
    }

    try {
      const template = await graphQlCall({
        queryTemplateObject: queries.CREATE_GENERATION_TEMPLATE_MUTATION,
        values: {
          name,
          actions: actions,
          ...(isAI && { layout: JSON.stringify(blocks) }),
          type: 'autoapp',
        },
        headerType: 'USER-AUTH',
      });
      if (template?._id) {
        const app = await graphQlCall({
          queryTemplateObject: queries.CREATE_AUTO_APP,
          values: {
            name: appName,
            description: appDescription,
            templateId: template?._id,
          },
          headerType: 'USER-AUTH',
        });
        history.push(
          `/console/generationTemplate/${template._id}/edit?app=${app._id}&editor=true`
        );
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Something went wrong. Please try again.',
        placement: 'topRight',
        duration: 1.5,
      });
    } finally {
      setIsLoading(false);
      setIsAIGenerating(false);
    }
  };

  const handleCreate = async () => {
    if (!!appName && !isLoading && !isAIGenerating) {
      try {
        setIsLoading(true);
        await handleCreateAutoApp(
          'New Template',
          JSON.stringify(defaultTemplateActionSet)
        );
      } catch (error) {
        console.error(error);
        notification.error({
          message: 'Something went wrong. Please try again.',
          placement: 'topRight',
          duration: 1.5,
        });
      }
    }
  };

  const handleGoBack = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.goBack();
  };

  return (
    <div className={styles.container}>
      <Link
        to="/console/"
        className={styles.backLink}
        prefixIcon={<ArrowLeft />}
      >
        <div onClick={handleGoBack}>Back</div>
      </Link>
      <ResizeIcon fill="#4957d8" />
      <div className={styles.title}>New App</div>
      <div className={styles.subtitle}>
        Create new Application and share with your customers
      </div>
      <div className={styles.tabsWrapper}>
        <div className={styles.suggestions}>
          Suggestions
          <ReloadIcon
            onClick={handleReloadPrompts}
            className={clsx(styles.reloadIcon, {
              [styles.loading]: loadingPrompts,
            })}
          />
        </div>
        <div className={styles.tabs}>
          {displayedPrompts.map((el) => (
            <PillTab
              key={el.name}
              id={el.prompt}
              height={30}
              selected={selectedTab === el.prompt}
              onSelect={() => handleSelectPrompt(el)}
            >
              {el.prompt}
            </PillTab>
          ))}
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.input}>
          <div className={styles.label}>Application Name</div>
          <Input
            border="stroke"
            placeholder="Name Your Application"
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
          />
        </div>
        <div className={styles.textarea}>
          <div className={styles.label}>Description</div>
          <TextArea
            height={120}
            placeholder="What is your app about?"
            value={appDescription}
            onChange={setAppDescription}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          appearance="highlighted"
          width={240}
          height={50}
          prefixIcon={!isAIGenerating && <MagicIcon />}
          disabled={!appName || !appDescription || isLoading || isAIGenerating}
          onClick={handleGenerate}
        >
          {isAIGenerating ? `Loading... ${progress}%` : 'Create with AI'}
        </Button>
        <Button
          appearance="stroke"
          width={240}
          height={50}
          disabled={!appName || isLoading || isAIGenerating}
          onClick={handleCreate}
        >
          {isLoading ? (
            <Loader color="#000000" size={16} />
          ) : (
            'Create from Scratch'
          )}
        </Button>
      </div>
    </div>
  );
};

export default NewApp;
