import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import { IAutoSassDesign } from 'store/autosaas/autosaasReducer';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { AUTH_USER_TOKEN_KEY } from '../../Constants';
import s from './PageLogin.module.scss';
import InputV2 from '../../Components/Common/Inputs/InputV2/InputV2';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PrimaryButton from '../../Components/Common/PrimaryButton/PrimaryButton';
import InputPassword from '../../Components/Common/Inputs/InputPassword/InputPassword';
import SpinnerWhite from '../../Components/Common/SpinnerWhite/SpinnerWhite';
import OnboardingLogo from 'Components/Common/OnboardingLogo/OnboardingLogo';

interface IProps {
  autoSassDesign?: IAutoSassDesign;
}

interface IValues {
  password: string;
  email: string;
}

const PageLogin = ({ autoSassDesign }: IProps) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isRedirected = queryParams.get('saas-redirect') === 'true';
    if (isRedirected) {
      notification.info({
        message: 'You have been redirected to your saas domain',
        placement: 'topRight',
        duration: 3,
      });
    }
  }, [location]);

  const onFinish = async (values: IValues) => {
    try {
      setLoading(true);
      const cognitoUser = await Auth.signIn(values.email, values.password);
      if (!cognitoUser) {
        throw new Error('user not found');
      }
      Document.prototype.cognitoUser = cognitoUser;

      //Customers that already got emails need this old way to finish their sign up process
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        let email = cognitoUser.challengeParam.userAttributes.email;
        localStorage.setItem('USER_EMAIL', email);
        history.replace({ pathname: '/console/signup/' + email });
        return;
      }
      notification.success({
        message: 'Log in successfully',
        placement: 'topRight',
        duration: 1.5,
        onClose: () => {
          history.replace({ pathname: '/console/autologin' });
        },
      });
    } catch (err: any) {
      console.log('ERROR', err);
      handleError(err);
    }
  };

  const handleError = (err: any) => {
    console.log('error:', err);
    localStorage.removeItem(AUTH_USER_TOKEN_KEY);
    notification.error({
      message: 'Error',
      description: err.message,
      placement: 'topRight',
    });
    setLoading(false);
  };

  return (
    <div className={s.container}>
      <OnboardingLogo />
      <div className={s.innerContainer}>
        <div className={s.header}>
          <p>Log In</p>
          <p className={s.subtitle}>
            Welcome to {autoSassDesign?.name ?? 'Autofunnel'}!
          </p>
          <p className={s.subtitle}>
            Build the website of the future with AI in seconds!
          </p>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email')
              .required('Email is required'),
            password: Yup.string().required('Password is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            onFinish(values);
            setSubmitting(false);
          }}
        >
          <Form className={s.form}>
            <div className={s.field1}>
              <InputV2
                width={370}
                placeholder="Email"
                name="email"
                type="text"
              />
            </div>
            <div className={s.field2}>
              <InputPassword
                width={370}
                placeholder="Password"
                name="password"
              />
            </div>
            <div className={s.submitButton}>
              <PrimaryButton width={230} type="submit" isBlackBtn={true}>
                {loading ? <SpinnerWhite size={27} /> : 'Log in'}
              </PrimaryButton>
            </div>
          </Form>
        </Formik>
        <div className={s.footer}>
          <p>Forgot your password?</p>
          <div className={s.link}>
            <Link to="/console/reset-password">Click here to reset</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoSassDesign: state.autosaas.autoSassDesign,
});

export default connect(mapStateToProps)(PageLogin);
