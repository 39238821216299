import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/rootReducer';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { MenuItems } from 'Pages/PageBookleTemplateEdior/Draggable/Sidebar/Sidebar';
import { AutoSassIconsOptions } from 'Components/SideMenu/constants';
import { ReactComponent as AppIcon } from 'Assets/icons/appIcons/templates.svg';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { createSocket, getToken } from 'utils/Utils';
import { BookleTemplateBlock, IGenerationTemplate, TemplateForm } from 'types';
import { getFormElement } from './AddFileForm';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import Link from 'UILib/Link/Link';

import styles from './AddNewAutoFile.module.scss';

interface IProps {
  autoApps?: IAutoApp[];
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const AddNewAutoFile = ({ autoApps, updateBlocks, templateBlocks }: IProps) => {
  const { appId, templateId } = useParams<{
    appId: string;
    templateId: string;
  }>();
  const [appData, setAppData] = useState<IAutoApp>();
  const [templateForms, setTemplateForms] = useState<TemplateForm[]>([]);
  const [fetchingForms, setFetchingForms] = useState<boolean>(false);
  const [currentFormIndex, setCurrentFormIndex] = useState<number>(0);
  const [formValidationError, setFormValidationError] = useState(false);
  const [generationData, setGenerationData] = useState<
    Record<string, Record<string, string | number>>
  >({});
  const [currentGenTaskId, setCurrentGenTaskId] = useState<string | null>(null);
  const socket = useRef<Socket | null>(null);
  const [initialTemplate, setInitialTemplate] = useState<IGenerationTemplate>();

  const history = useHistory();

  useEffect(() => {
    if (!socket.current) {
      socket.current = createSocket();
      socket.current.on(
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );
      socket.current.on('connect', () => {
        console.log('connected to server');
      });
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          'generating-task-info-response',
          handleGenerationTaskUpdates
        );
      }
    };
  }, []);

  useEffect(() => {
    if (templateForms.length > 0) {
      const form = templateForms[currentFormIndex];
      if (!generationData[form.type]) {
        const generationDataCopy = { ...generationData };
        generationDataCopy[form.type] = {};
        form.variables.forEach((variable) => {
          generationDataCopy[form.type][variable.id] = '';
        });
        setGenerationData(generationDataCopy);
      }
    }
  }, [currentFormIndex, templateForms]);

  useEffect(() => {
    if (!appData && !!appId) {
      if (autoApps?.length) {
        setAppData(autoApps.find((e) => e._id === appId));
      } else {
        graphQlCall({
          queryTemplateObject: queries.GET_ONE_AUTO_APP,
          headerType: 'USER-AUTH',
          values: {
            id: appId,
          },
        }).then((data) => {
          setAppData(data);
        });
      }
    }
  }, [appId, autoApps, appData]);

  useEffect(() => {
    if (currentGenTaskId) {
      subscribeOnGenerationTaskUpdates(currentGenTaskId);
    }
  }, [currentGenTaskId]);

  useEffect(() => {
    if (templateId !== '') {
      setFetchingForms(true);

      graphQlCall({
        queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
        values: { id: templateId },
        headerType: 'USER-AUTH',
      })
        .then((data) => {
          if (data.actions) {
            setTemplateForms(data.actions.forms);
          } else {
            setTemplateForms([]);
          }
          updateBlocks(data.layout);
        })
        .finally(() => setFetchingForms(false));
    }
  }, [templateId]);

  useEffect(() => {
    if (appData?._id && appData?.template?._id) {
      setFetchingForms(true);
      graphQlCall({
        queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
        values: { id: appData?.template?._id },
        headerType: 'USER-AUTH',
      })
        .then((data) => {
          setInitialTemplate(data);

          if (data.actions) {
            setTemplateForms(data.actions.forms);
          } else {
            setTemplateForms([]);
          }
        })
        .finally(() => setFetchingForms(false));
    }
  }, [appData]);

  const updateGenerationBlockContent = (path: string, content: string) => {
    const pathComponents = path.split('.');
    const variable = pathComponents[pathComponents.length - 1];

    for (const block of templateBlocks) {
      if (block.variable === variable) {
        block.generating = false;
        if (block.type === MenuItems.TEXT_BLOCK) {
          block.text = [
            {
              type: 'paragraph',
              children: [
                {
                  text: content,
                },
              ],
            },
          ];
        } else if (block.type === MenuItems.IMAGE_BLOCK) {
          block.image = content;
        }

        updateBlocks([...templateBlocks]);
        break;
      }
    }
  };

  const handleGenerationTaskUpdates = (payload: any) => {
    if (payload.action === 'content generated') {
      if (payload.data) {
        updateGenerationBlockContent(payload.path, payload.data.result);
      }
    }
  };

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    if (socket.current) {
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  const handleGenerationStart = async () => {
    for (const block of templateBlocks) {
      if (block.variable) {
        block.generating = true;
      }
    }
    updateBlocks([...templateBlocks]);

    const task = await graphQlCall({
      queryTemplateObject: queries.CREATE_GENERATION_TASK_MUTATION,
      values: { templateId: appData?.template?._id },
      headerType: 'USER-AUTH',
    });

    setCurrentGenTaskId(task.task._id);

    const keys = Object.keys(generationData);
    const response = await graphQlCall({
      queryTemplateObject: queries.PUSH_DATA_TO_GENERATION_TASK_MUTATION,
      values: {
        taskId: task.task._id,
        data: JSON.stringify(generationData[keys[0]]),
        path: 'root',
      },
      headerType: 'USER-AUTH',
    });
  };

  const handleNextClick = () => {
    const form = templateForms[currentFormIndex];
    const formValues = generationData[form.type];
    for (const key in formValues) {
      if (!formValues[key]) {
        setFormValidationError(true);
        return;
      }
    }
    setFormValidationError(false);
    if (currentFormIndex < templateForms.length - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    } else {
      handleGenerationStart();
      handleCreateNewTemplate();
    }
  };

  const handleCreateNewTemplate = async () => {
    graphQlCall({
      queryTemplateObject: queries.CREATE_GENERATION_TEMPLATE_MUTATION,
      values: {
        name: 'New Template',
        type: appData?._id,
        actions: JSON.stringify(initialTemplate?.actions),
        layout: JSON.stringify(initialTemplate?.layout),
      },
      headerType: 'USER-AUTH',
    }).then((data) =>
      history.push(
        `/console/generationTemplate/${data._id}/edit?app=${appData?._id}&editor=true`
      )
    );
  };

  if (fetchingForms) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#4957d8" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Link
        to={`/console/app/${appData?.name.toLowerCase().replace(/\s+/g, '')}/${
          appData?._id
        }`}
        className={styles.backLink}
        prefixIcon={<Back />}
      >
        Dashboard
      </Link>
      <div className={styles.header}>
        {AutoSassIconsOptions.find((item) => item.path === appData?.iconUrl)
          ?.icon || <AppIcon />}
        <div className={styles.title}>
          {templateForms[currentFormIndex]?.name}
        </div>
        <div className={styles.subtitle}></div>
      </div>
      <div className={styles.form}>
        {templateForms[currentFormIndex]?.variables.map((variable) => (
          <div key={variable.id} className={styles.formElement}>
            {variable.label}
            {getFormElement(
              templateForms[currentFormIndex].type,
              variable,
              generationData,
              formValidationError,
              setGenerationData
            )}
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button appearance="stroke" onClick={handleCreateNewTemplate}>
          Skip this Step
        </Button>
        <Button
          appearance="solid"
          onClick={handleNextClick}
          postfixIcon={<ArrowRightIcon />}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoApps: state.autosaas.autoApps,
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAutoFile);
