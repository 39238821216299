// production constants
export const AUTH_USER_TOKEN_KEY = 'LanderAmplify.TokenKey';
export const API_ENDPOINT = 'http://localhost:3001/';
export const API_VIDEO_UPLOAD = 'http://localhost:3001/upload';
export const API_VIDEO_STATUS = 'http://localhost:3001/status';
export const TEMPLATES_URL = 'https://cdn.autofunnel.ai/templates';
export const STRIPE_KEY =
  'pk_live_51MvOYPIw2eOqESjKs9CnD41kxZYR7ocWVq3cvzl2KXyQYxdTKtIwXkcnpkrViXhT48aF5JAV07ahKb9QvLWHHgbE00OPUrS6CN';
export const DATA_URL = 'https://s3.amazonaws.com/shomio.data.images';
export const AMPLIFY_CLIENT_ID = '15sq4lnuel1ann4q6124vled3d';
export const AMPLIFY_POOL_ID = 'us-east-1_x4GSuj2gz';
export const STRIPE_SUCCESS_URL =
  'http://app.mech.com/console/success-subscribed';
export const STRIPE_CANCEL_URL = 'http://app.mech.com/console/cancel-subscribe';
export const ENVIRONMENT: 'DEV' | 'PROD' = 'PROD';
export const CONSULT_URL = 'https://app.autofunnel.ai';
export const PAGECRAFT_API_URL = 'https://pagecraft-api-prod.autofunnel.ai';
export const PAGECRAFT_ANALYTICS_API_URL =
  'https://pagecraft-analytics-prod.autofunnel.ai';
export const SUPER_ADMIN_PASSWORD = 'T5S8mFagftVGnY5h';
export const StripeProducts = {
  //DEV todo update with PROD values
  Basic: {
    monthly: {
      '1000': { id: 'price_1Mw6o5Iw2eOqESjKxDrKRS3W', price: '693' },
      '750': { id: 'price_1Mw6o5Iw2eOqESjK9lRh8QVu', price: '520' },
      '500': { id: 'price_1Mw6o5Iw2eOqESjKTgJGAk6s', price: '346' },
      '250': { id: 'price_1Mw6o5Iw2eOqESjKq6ApQgrg', price: '173' },
      '100': { id: 'price_1N3jxsIw2eOqESjKo9pCXcOV', price: '149' },
    },
    annually: {
      '1000': { id: 'price_1Mw6o5Iw2eOqESjKeqlcMzK5', price: '6648' },
      '750': { id: 'price_1Mw6o5Iw2eOqESjK8Zlhl1ji', price: '4992' },
      '500': { id: 'price_1Mw6o5Iw2eOqESjKuh4SVKJd', price: '3324' },
      '250': { id: 'price_1Mw6o5Iw2eOqESjKLEpDKDZr', price: '1668' },
      '100': { id: 'price_1N3k2WIw2eOqESjKmQOHCm0j', price: '1428' },
    },
    annuallyDiscount: {
      '100': { id: 'price_1MzDsYIw2eOqESjKUIY6IIzS', price: '600' },
    },
  },
  Plus: {
    monthly: {
      '250': { id: 'price_1Mw6nxIw2eOqESjKld0CA17x', price: '199' },
      '750': { id: 'price_1Mw6nxIw2eOqESjKP1pA2eDD', price: '332' },
      '1250': { id: 'price_1Mw6nxIw2eOqESjKbgfpqCqp', price: '497' },
      '1750': { id: 'price_1Mw6nxIw2eOqESjKdxobUsQ1', price: '695' },
      '2500': { id: 'price_1Mw6nwIw2eOqESjK3fnodrF0', price: '995' },
    },
    annually: {
      '250': { id: 'price_1Mw6nxIw2eOqESjKYaUlb8Ls', price: '1908' },
      '750': { id: 'price_1Mw6nxIw2eOqESjKNkSSlTnh', price: '3180' },
      '1250': { id: 'price_1Mw6nxIw2eOqESjKEpWEOkQU', price: '4764' },
      '1750': { id: 'price_1Mw6nxIw2eOqESjK3dobeAFx', price: '6672' },
      '2500': { id: 'price_1Mw6nxIw2eOqESjKoWL5z7V0', price: '9552' },
    },
    annuallyDiscount: {
      '250': { id: 'price_1MzDsYIw2eOqESjK8B7rWmMY', price: '1200' },
    },
  },
  Pro: {
    monthly: {
      '500': { id: 'price_1Mw6noIw2eOqESjKhyLpyYsm', price: '299' },
      '1250': { id: 'price_1Mw6noIw2eOqESjKL2yVM3yE', price: '374' },
      '2500': { id: 'price_1Mw6noIw2eOqESjKbXqBiIyT', price: '747' },
      '3750': { id: 'price_1Mw6noIw2eOqESjKtGHsssg1', price: '1121' },
      '5000': { id: 'price_1Mw6noIw2eOqESjKKh1bMWfk', price: '1495' },
    },
    annually: {
      '500': { id: 'price_1Mw6noIw2eOqESjKGbIgmJ8g', price: '2868' },
      '1250': { id: 'price_1Mw6noIw2eOqESjK3fM5ZG4s', price: '3588' },
      '2500': { id: 'price_1Mw6noIw2eOqESjK8IGy1eeu', price: '7164' },
      '3750': { id: 'price_1Mw6noIw2eOqESjKhSWTR6Q4', price: '10764' },
      '5000': { id: 'price_1Mw6noIw2eOqESjKUCa6WD1R', price: '14340' },
    },
    annuallyDiscount: {
      '250': { id: 'price_1MzDsYIw2eOqESjKC5j07vl0', price: '1800' },
    },
  },
};

export const SSE_SERVER = 'https://ai-cuno.autofunnel.ai/email/';
export const AI_COPY_CRAFT_TEST_EMAIL_URL =
  'https://ai-cuno.autofunnel.ai/email';
export const AI_COPY_CRAFT_TEST_SALES_URL =
  'https://ai-cindy.autofunnel.ai/sales/';
export const AI_COPY_CRAFT_TEST_BRAIN_URL = 'http://129.213.130.208:7861';
export const AI_COPY_CRAFT_EMAIL_URL =
  'wss://text-ai.autofunnel.ai/api/v2/stream';
export const AI_COPY_CRAFT_SALES_URL =
  'wss://ai-sales-text-server.autofunnel.ai/api/v2/stream';
export const SOCKET_URL = 'wss://pagecraft-api-prod.autofunnel.ai';
export const SENTRY_DNS =
  'https://841f1bc4950d9348bfbb99cfc2e23c51@o4506429919920128.ingest.sentry.io/4506434832629760';
export const NO_SOCKET_CREDENTIALS = false;
export const ENCRYPTION_KEY = 'm9gHGAn5LCFEWD2y7Ur3qXxSfJ8dck';

export const BOOKLE_PLANES = {
  monthly: [
    {
      name: 'Free',
      descriptions: 'For Hobbyists just getting started',
      features: 'Only Small Books',
      value: 0,
      creditsCount: 1,
      pageCount: 30,
      stripePriceId: 'price_1OuKRfIw2eOqESjKMA3nwUpT',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Small',
      currentPlan: false,
    },
    {
      name: 'Plus',
      descriptions: 'For Individuals and Small Teams',
      features: 'Up to Medium Books',
      value: 49,
      creditsCount: 25,
      pageCount: 150,
      stripePriceId: 'price_1OuKUDIw2eOqESjKRZhxFTPu',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Medium',
      currentPlan: false,
    },
    {
      name: 'Pro',
      descriptions: 'For Businesses & Agencies',
      features: 'Up to Large Books',
      value: 149,
      creditsCount: 100,
      pageCount: 200,
      stripePriceId: 'price_1OuKV7Iw2eOqESjKebSc6cOB',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Large',
      currentPlan: false,
    },
  ],
  annually: [
    {
      name: 'Free',
      descriptions: 'For Hobbyists just getting started',
      features: 'Only Small Books',
      value: 0,
      creditsCount: 1,
      pageCount: 30,
      stripePriceId: 'price_1OuKRfIw2eOqESjKMA3nwUpT',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Small',
      currentPlan: false,
    },
    {
      name: 'Plus Annual',
      descriptions: 'For Individuals and Small Teams',
      features: 'Up to Medium Books',
      value: 349,
      creditsCount: 310,
      pageCount: 150,
      stripePriceId: 'price_1OuKW3Iw2eOqESjK2Vbkof6N',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Medium',
      currentPlan: false,
    },
    {
      name: 'Pro Annual',
      descriptions: 'For Businesses & Agencies',
      features: 'Up to Large Books',
      value: 999,
      creditsCount: 1300,
      pageCount: 200,
      stripePriceId: 'price_1OvoUSIw2eOqESjKdn4JLgIX',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Large',
      currentPlan: false,
    },
  ],
  upsells: {
    Plus: {
      name: 'Plus Discount Annual',
      descriptions: 'For Individuals and Small Teams',
      features: 'Up to Medium Books',
      promoTextHeader: 'Want 50% OFF of Bookle... Forever?',
      promoText: [
        "Because you're a new customer, we're giving you a one time offer to get Bookle 50% OFF if you upgrade to an annual plan.",
        "This means you'll just pay <b>$294</b> per year (which averages only <b>$24/month!</b>)",
      ],

      value: 249,
      creditsCount: 310,
      pageCount: 150,
      stripePriceId: 'price_1OvOUCIw2eOqESjKtvMzGJwD',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Medium',
      currentPlan: false,
    },
    Pro: {
      name: 'Pro Discount Annual',
      descriptions: 'For Businesses & Agencies',
      features: 'Up to Large Books',
      promoTextHeader: 'Want 70% OFF of Bookle... Forever?',
      promoText: [
        "Because you're a new customer, we're giving you a one time offer to get Bookle 70% OFF if you upgrade to an annual plan.",
        "That means you'll just pay <b>$540</b> per year (which averages only <b>$45/month</b>).",
      ],
      value: 540,
      creditsCount: 1300,
      pageCount: 200,
      stripePriceId: 'price_1OvOVbIw2eOqESjKXQb3ElQS',
      stripeProductId: 'prod_Pjo3JNo8epZuRw',
      type: 'Large',
      currentPlan: false,
    },
  },
};

export const EMAIL_DOMAIN = 'nikforce.com';
