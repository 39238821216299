import { connect } from 'react-redux';
import { ReactComponent as GlobeIcon } from 'Assets/icons/domainIcon.svg';
import { domainAddCNAMEPopup } from 'store/domains/domainsActions';
import { RootState } from 'store/rootStore';
import { IDomain } from 'types';
import Button from 'UILib/Button/Button';
import Popup from 'UILib/Popup/Popup';

import styles from './AddCNAMEPopup.module.scss';

interface IProps {
  domains: IDomain[];
  openDomainAddCNAMEPopup: boolean;
  toggleAddCNAMEPopup: (payload: boolean) => void;
}

const AddCNAMEPopup = ({
  domains,
  openDomainAddCNAMEPopup,
  toggleAddCNAMEPopup,
}: IProps) => {
  if (!openDomainAddCNAMEPopup) return null;

  const handleOpenArticle = () => {
    window.open(
      'https://help.autofunnel.ai/support/solutions/articles/202000060198-managing-and-using-your-domains-in-autofunnel',
      '_blank'
    );
  };

  return (
    <Popup
      onClose={() => toggleAddCNAMEPopup(false)}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <GlobeIcon width={50} height={50} fill="black" />
        <div className={styles.title}>Add CNAME</div>
        <div className={styles.subtitle}>
          <span className={styles.paragraph}>
            In order for your domain integration to work, you will need to log
            into wherever you purchased your domain and add a CNAME record that
            points{' '}
          </span>
          <span className={styles.domain}>
            <b>{domains[domains.length - 1].name}</b> toward{' '}
            <b>live.autofunnel.ai</b>.
          </span>
          <span className={styles.paragraph}>
            If you need some help, check out{' '}
            <a
              href="https://help.autofunnel.ai/support/solutions/articles/202000060198-managing-and-using-your-domains-in-autofunnel"
              rel="noreferrer"
              target="_blank"
              className={styles.url}
            >
              our detailed instructions
            </a>
            . You can also reach out to your domain registrar directly.
          </span>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button height={50} appearance="stroke" onClick={handleOpenArticle}>
          How to Add?
        </Button>
        <Button
          height={50}
          appearance="highlighted"
          onClick={() => toggleAddCNAMEPopup(false)}
        >
          I've done this
        </Button>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  openDomainAddCNAMEPopup: state.domains.openDomainAddCNAMEPopup,
  domains: state.domains.items,
});

const mapDispatchToProps = {
  toggleAddCNAMEPopup: (payload: boolean) => domainAddCNAMEPopup(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCNAMEPopup);
