export const GET_MY_SASS_PLAN_PRODUCTS = {
  operationName: 'getMySaasPlanProducts',
  query: `query getMySaasPlanProducts {
              getMySaasPlanProducts {
                    _id
                    products {
                        name
                        stripeId
                        prices {
                            name
                            subHeader
                            description
                            price
                            trial
                            paymentPeriod
                            currency
                            stripeId
                            apps {
                                key
                                enabled
                                name
                                items {
                                    name
                                    limit
                                    option
                                    unlimited
                                }
                            }
                        }
                    }
              }
          }`,
};
