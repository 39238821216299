import { useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './RadioButton.module.scss';

interface RadioButtonProps {
  label: string | JSX.Element;
  name: string;
  value: string;
  checked?: boolean;
  className?: string;
  onChange: (value: string) => void;
}

const RadioButton = ({
  label,
  name,
  value,
  checked = false,
  className,
  onChange,
}: RadioButtonProps) => {
  const [isSelected, setIsSelected] = useState(checked);

  const handleChange = () => {
    setIsSelected(true);
    onChange(value);
  };

  useEffect(() => {
    setIsSelected(checked);
  }, [checked]);

  return (
    <label className={clsx(styles.radioButton, className)}>
      {label}
      <input
        type="radio"
        name={name}
        value={value}
        checked={isSelected}
        onChange={handleChange}
      />
      <span className={styles.check} />
    </label>
  );
};

export default RadioButton;
