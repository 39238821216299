export const CREATE_AUTO_APP = {
  operationName: 'createAutoApp',
  query: `mutation createAutoApp ($name: String!, $description: String, $iconUrl: String, $templateId: String) {
              createAutoApp (name: $name, description: $description, iconUrl: $iconUrl, templateId: $templateId) {
                    _id
                    template { _id }
                    name
                    description
                    iconUrl
                    createdAt
                    updatedAt
              }
          }`,
};
