import { ChangeEvent, useState } from 'react';
import { ReactComponent as FilterIcon } from 'Assets/icons/filter.svg';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Button from 'UILib/Button/Button';
import { Tag } from '../Helper/types';

import s from 'Pages/PageContacts/SmartList/SmartListPopup.module.scss';

interface Props {
  smart: boolean;
  setSmart: (value: boolean) => void;
  onAddNewSmartList: (value: any) => void;
  uniqueTags: Tag[];
  fetchSmartLists: () => void;
}

const SmartList = ({
  fetchSmartLists,
  setSmart,
  onAddNewSmartList,
  smart,
  uniqueTags,
}: Props) => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const [includeAllTagsArray, setIncludeAllArray] = useState<string[]>([]);
  const [name, setName] = useState('');

  const handleSmartClose = () => {
    if (smart) setSmart(false);
    setTagsArray([]);
    setName('');
  };

  const handleSmartAdd = async () => {
    if (!btnDisabled) {
      setSmart(false);
      const newListName = name.trim();
      const exact: string[] = [];
      const includeAll: string[] = includeAllTagsArray;
      const includeAny: string[] = [];
      const excludeAll: string[] = [];
      const maxContactAge = undefined;
      try {
        const response = await graphQlCall({
          queryTemplateObject: QUERIES.CREATE_SMART_LIST,
          values: {
            name: newListName,
            exact,
            includeAll,
            includeAny,
            excludeAll,
            maxContactAge,
          },
          headerType: 'USER-AUTH',
        });
        if (response) {
          onAddNewSmartList(response);
          setName('');
          setTagsArray([]);
          setIncludeAllArray([]);
          setBtnDisabled(true);
        } else {
          throw new Error('Failed to create smart list');
        }
      } catch (error) {
        fetchSmartLists();
        console.error('Error creating smart list:', error);
      }
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
    updateSecondBtnDisabled(value, tagsArray);
  };

  const updateSecondBtnDisabled = (nameValue: string, tagsArray: string[]) => {
    if (nameValue.trim() === '' || tagsArray.length === 0) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  };

  const handleTagClick = (tag: Tag) => {
    const tagName = tag.name;
    const tagId = tag._id;

    if (!tagsArray.includes(tagName)) {
      const updatedTags = [...tagsArray, tagName];
      setTagsArray(updatedTags);
      updateSecondBtnDisabled(name, updatedTags);

      const updatedExactTags = [...includeAllTagsArray, tagId];
      setIncludeAllArray(updatedExactTags);
    }
  };

  const handleTagRemove = (tagToRemove: string) => {
    const updatedTags = tagsArray.filter((tag) => tag !== tagToRemove);
    setTagsArray(updatedTags);
    updateSecondBtnDisabled(name, updatedTags);
  };

  if (!smart) return null;

  return (
    <div className={s.container}>
      <div className={s.overlay} onClick={handleSmartClose} />
      <div className={s.smartCover}>
        <div>
          <div>
            <div>
              <FilterIcon />
            </div>
            <div className={s.filterText}>
              <div className={s.filterTopText}>Create Smart List</div>
              <div className={s.filterBottomtext}>based on your tags</div>
            </div>
            <CrossIcon className={s.close} onClick={handleSmartClose} />
          </div>
          <div className={s.hr}></div>
          <p>
            Name<span>*</span>
          </p>
          <input
            placeholder={'Give a Name'}
            value={name}
            onChange={handleNameChange}
            className={s.filterInput}
          />
        </div>
        <div>
          <p>
            Tags<span>*</span>
          </p>
          <Dropdown
            label="Select Tags"
            size="medium"
            type="stroke"
            options={uniqueTags.map((tag) => ({
              label: tag.name,
              value: tag._id,
            }))}
            onChange={(selectedTagId) => {
              const selectedTag = uniqueTags.find(
                (tag) => tag._id === selectedTagId
              );
              if (selectedTag) {
                handleTagClick(selectedTag);
              }
            }}
          />
          <div className={s.addedTags}>
            {tagsArray.map((tag, index) => (
              <div key={index} className={s.tag}>
                {tag}
                <CrossIcon
                  className={s.crossBtn}
                  onClick={() => handleTagRemove(tag)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={s.filterBtns}>
          <Button className={s.whiteBtn} onClick={handleSmartClose}>
            Cancel
          </Button>
          <Button
            className={btnDisabled ? s.disabledBtn : s.blackBtn}
            onClick={handleSmartAdd}
            disabled={btnDisabled}
          >
            Create List
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SmartList;
