import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import { BookleTemplateBlock } from 'types';
import { MenuItems } from './Sidebar/Sidebar';

export const addItemToContainer = (
  item: BookleTemplateBlock,
  blocks: Array<BookleTemplateBlock>
): BookleTemplateBlock[] => {
  const newArray = [...blocks];

  let newItem = item.id
    ? findItemById(newArray, item.id, true) || item
    : createNewItem(item);
  newArray.push(newItem);
  return newArray;
};

const createNewItem = (item: any) => {
  const newItem = {
    id: generateNewIndex(),
    type: item?.type,
  };

  if (item?.type?.includes('section')) {
    return {
      ...newItem,
      items: Array.from({ length: Number(item?.type.split('_')[1]) }).map(
        () => ({
          id: generateNewIndex(),
          items: [],
        })
      ),
    };
  }

  return newItem;
};

export function findItemById(
  items: any[],
  id: string,
  isRemove?: boolean
): any {
  if (!Array.isArray(items)) {
    return null;
  }

  for (let i = 0; i < items.length; i++) {
    let item = items[i];

    if (item?.id === id) {
      if (isRemove) {
        return items.splice(i, 1)[0];
      }
      return item;
    }

    if (item?.items && item?.items?.length > 0) {
      let found = findItemById(item.items, id, isRemove);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function findAndDuplicate(
  array: BookleTemplateBlock[],
  targetId: string
) {
  function duplicateItem(item: BookleTemplateBlock): BookleTemplateBlock {
    const newItem = JSON.parse(JSON.stringify(item));
    const queue: BookleTemplateBlock[] = [newItem];

    while (queue.length > 0) {
      const current = queue.shift();
      if (current) {
        current.id = generateNewIndex();
        if (current.items) {
          queue.push(...current.items);
        }
      }
    }

    return newItem;
  }

  function recursiveFindAndInsert(arr: any[], id: string): boolean {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]?.id === id) {
        const newItem = duplicateItem(arr[i]);
        arr?.splice(i + 1, 0, newItem);
        return true;
      } else if (arr[i]?.items && arr[i]?.items.length > 0) {
        if (recursiveFindAndInsert(arr[i]?.items, id)) {
          return true;
        }
      }
    }
    return false;
  }

  recursiveFindAndInsert(array, targetId);
}

export const moveItem = (
  hoveredItem: BookleTemplateBlock,
  draggedItem: BookleTemplateBlock,
  isFirstHalf: boolean,
  items: Array<BookleTemplateBlock>,
  setItems: (items: Array<BookleTemplateBlock>) => void,
  parentId?: string
) => {
  const newArray = [...items];
  let parent: any = parentId ? findItemById(newArray, parentId) : null;
  const hoverItem = findItemById(newArray, hoveredItem.id as string);

  const container = parent?.id ? parent.items : newArray;

  const newIndex = container.indexOf(hoverItem) + (isFirstHalf ? 0 : 1);

  const itemToMove = draggedItem.id
    ? findItemById(newArray, draggedItem.id as string, true) || draggedItem
    : createNewItem(draggedItem);

  if (parent?.id) {
    parent.items.splice(newIndex, 0, itemToMove);
  } else {
    newArray.splice(newIndex, 0, itemToMove);
  }

  setItems(newArray);
};

export default addItemToContainer;

export function generateNewIndex(): string {
  return Math.random().toString(36).substr(2, 9);
}

export function isChildOf(
  items: BookleTemplateBlock[],
  parentId: string,
  childId: string
): boolean {
  const parent = findItemById(items, parentId);

  if (!parent || !parent.items || parent.items.length === 0) {
    return false;
  }

  return recursiveChildSearch(parent.items, childId);
}

function recursiveChildSearch(
  items: BookleTemplateBlock[] | undefined,
  childId: string
): boolean {
  if (!items) return false;
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === childId) {
      return true;
    }

    if (items[i].items && (items?.[i]?.items as any).length > 0) {
      if (recursiveChildSearch(items[i].items, childId)) {
        return true;
      }
    }
  }

  return false;
}

export const updateItem = (
  key: keyof BookleTemplateBlock,
  value: number | string | boolean | never[],
  templateBlocks: BookleTemplateBlock[],
  updateBlocks: (payload: BookleTemplateBlock[]) => void,
  item: BookleTemplateBlock
) => {
  const newArray = [...templateBlocks];
  const buttonItem: BookleTemplateBlock | undefined = findItemById(
    newArray,
    item.id as string
  );

  if (!buttonItem) return;

  buttonItem[key] = value;

  updateBlocks(newArray);
};

export const updateItemStyle = (
  key: string,
  value: number | string,
  templateBlocks: BookleTemplateBlock[],
  updateBlocks: (payload: BookleTemplateBlock[]) => void,
  item: BookleTemplateBlock
) => {
  const newArray = [...templateBlocks];
  let buttonItem: BookleTemplateBlock | undefined = findItemById(
    newArray,
    item.id as string
  );

  if (!buttonItem) return;

  (buttonItem as any).styles = {
    ...buttonItem.styles,
    [key]: value,
  };

  updateBlocks(newArray);
};

export class Preview2HTML {
  generateStyles(
    styles?: BookleTemplateBlock['styles'],
    hasSpacing?: boolean
  ): { containerStyles: string; rootContainerStyles: string } {
    if (!styles || !hasSpacing)
      return { containerStyles: '', rootContainerStyles: '' };

    const {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      marginTop,
      marginBottom,
      marginRight,
      marginLeft,
    } = styles.blockSpacing;

    const containerStylesString = `
      ${
        paddingLeft
          ? `padding-left: ${
              typeof paddingLeft === 'number' ? `${paddingLeft}px` : paddingLeft
            };`
          : ''
      }
      ${
        paddingRight
          ? `padding-right: ${
              typeof paddingRight === 'number'
                ? `${paddingRight}px`
                : paddingRight
            };`
          : ''
      }
      ${
        paddingTop
          ? `padding-top: ${
              typeof paddingTop === 'number' ? `${paddingTop}px` : paddingTop
            };`
          : ''
      }
      ${
        paddingBottom
          ? `padding-bottom: ${
              typeof paddingBottom === 'number'
                ? `${paddingBottom}px`
                : paddingBottom
            };`
          : ''
      }
  
    `.trim();

    const rootContainerStylesString = `
      ${
        marginLeft
          ? `padding-left: ${
              typeof marginLeft === 'number' ? `${marginLeft}px` : marginLeft
            };`
          : ''
      }
      ${
        marginRight
          ? `padding-right: ${
              typeof marginRight === 'number' ? `${marginRight}px` : marginRight
            };`
          : ''
      }
      ${
        marginTop
          ? `padding-top: ${
              typeof marginTop === 'number' ? `${marginTop}px` : marginTop
            };`
          : ''
      }
      ${
        marginBottom
          ? `padding-bottom: ${
              typeof marginBottom === 'number'
                ? `${marginBottom}px`
                : marginBottom
            };`
          : ''
      }
  
    `.trim();

    return {
      containerStyles: containerStylesString.replace(/\s+/g, ' '),
      rootContainerStyles: rootContainerStylesString.replace(/\s+/g, ' '),
    };
  }

  constructTextBlock(blockId: string, haveMarginBottom?: boolean): string {
    const textEditorElement = document.getElementById(`text_editor_${blockId}`);

    if (!textEditorElement) {
      return '';
    }

    const contentEditableElements = textEditorElement.querySelectorAll(
      '[contentEditable="true"]'
    );
    contentEditableElements.forEach((element) => {
      element.removeAttribute('contentEditable');
    });

    const paragraphs = textEditorElement.querySelectorAll('p');
    paragraphs.forEach((p, index) => {
      p.style.marginTop = '0';
    });

    const htmlContent = textEditorElement.innerHTML;

    contentEditableElements.forEach((element) => {
      element.setAttribute('contentEditable', 'true');
    });
    if (haveMarginBottom) {
      paragraphs[paragraphs.length - 1].style.marginBottom = '1em';
    }

    return htmlContent;
  }

  constructBlock(previewBlock: BookleTemplateBlock): string {
    let block = '';
    const { containerStyles, rootContainerStyles } = this.generateStyles(
      previewBlock.styles,
      previewBlock.hasSpacing
    );

    const containerColor =
      previewBlock.color && previewBlock.hasColor
        ? `background-color: ${previewBlock.color};`
        : '';

    switch (previewBlock.type) {
      case MenuItems.IMAGE_BLOCK: {
        const height = `height: ${previewBlock.styles?.height ?? 40}px;`;
        const childTag = `<img style="max-width: 100%; ${
          previewBlock.customHeight && height
        }" alt="img" title="img" src="${previewBlock.image}"/>`;
        block = `<div style="${containerColor} ${containerStyles}">
                 <table role="presentation" style="border-collapse: collapse; width: 100%;">
                            <tr>
                              <td align="center" valign="middle" style="overflow: hidden; padding: 0;">
                                 ${
                                   previewBlock.url
                                     ? `<a style="text-decoration: none;" href="${previewBlock.url}" rel="noopener noreferrer">${childTag}</a>`
                                     : childTag
                                 }
                              </td>
                            </tr>
                   </table>
       </div>`;
        break;
      }
      case MenuItems.TEXT_BLOCK: {
        const htmlContent = this.constructTextBlock(previewBlock.id, true);
        block = `<div style="${containerColor} ${containerStyles}">${htmlContent}</div>`;
        break;
      }
      case MenuItems.BUTTON_BLOCK: {
        const backgroundColor = !previewBlock.isSpacer
          ? `background-color: ${
              previewBlock.styles?.backgroundColor ?? '#000000'
            };`
          : '';

        const height = `height: ${previewBlock.styles?.height ?? 40}px;`;
        const width = `width: ${previewBlock.styles?.width ?? 200}px;`;
        const borderRadius = `border-radius: ${
          previewBlock.styles?.borderRadius ?? 0
        }px;`;

        const htmlContent = this.constructTextBlock(previewBlock.id);

        const childTag = `<table role="presentation" style="border-collapse: collapse; ${backgroundColor} ${height} ${width} ${borderRadius}">
                            <tr>
                              <td align="center" valign="middle" style="overflow: hidden; padding: 0;">
                                ${htmlContent}
                              </td>
                            </tr>
                          </table>`;

        block = `<div style="${containerColor} ${containerStyles}">
                <table role="presentation" style="border-collapse: collapse; width: 100%">
                  <tr>
                    <td align="${
                      previewBlock?.alignment || 'center'
                    }" style="padding: 0;">
                      ${
                        previewBlock.url
                          ? `<a style="text-decoration: none;" href="${previewBlock.url}" rel="noopener noreferrer">${childTag}</a>`
                          : childTag
                      }
                    </td>
                  </tr>
                </table>
                </div>`;
        break;
      }
      case MenuItems.DIVIDER_BLOCK: {
        const backgroundColor = !previewBlock.isSpacer
          ? `background-color: ${
              previewBlock.styles?.backgroundColor ?? '#b9b9b9'
            };`
          : '';

        const height = `height: ${previewBlock.styles?.height ?? 1}px;`;

        block = `<div role="presentation" style="${containerStyles} ${containerColor} width: 100%;">
                    <div style="${backgroundColor} ${height}"></div>
                </div>`;
        break;
      }
      case MenuItems.ONE_SECTION:
      case MenuItems.TWO_SECTION:
      case MenuItems.THREE_SECTION:
      case MenuItems.FOUR_SECTION: {
        block = `<table role="presentation" style="border-collapse: collapse; width: 100%; ${containerColor}">
                  <tr>${previewBlock.items
                    ?.map(
                      (item: BookleTemplateBlock) =>
                        `<td style="width: ${
                          (previewBlock?.items as any).length > 0
                            ? 100 / (previewBlock?.items as any).length
                            : 1
                        }%; padding: 0;">
                        <div style="${containerStyles}">
                          ${this.setCorrespondingBlocks(
                            item.items || [],
                            undefined,
                            true
                          )}
                        </div>
                        </td>`
                    )
                    .join('')}
                  </tr>
                </table>`;
        break;
      }
    }

    return `<div style="${rootContainerStyles}">${block}</div>`;
  }

  setCorrespondingBlocks(
    previewBlocks: BookleTemplateBlock[],
    templateBlockStyles?: IBookleTemplateBlockStyles,
    isSection?: boolean
  ): string {
    let blockContainer = '';
    for (const previewBlock of previewBlocks) {
      blockContainer += this.constructBlock(previewBlock);
    }

    if (isSection) {
      return blockContainer;
    }

    const styleLinks = Array.from(
      document.querySelectorAll('link[rel="stylesheet"]')
    )
      .map((link) => link.outerHTML)
      .join('\n');

    const styles = templateBlockStyles
      ? `background-color: ${templateBlockStyles.bodyColor}; width: ${templateBlockStyles.bodyWidth}px;`
      : '';

    return `
          <!DOCTYPE html>
          <html lang='en'>
          <head>
              <meta charset='UTF-8'>
              <meta name='viewport' content='width=device-width, initial-scale=1.0'>
              <title>Generated HTML</title>
              ${styleLinks}
          </head>
          <body style='background-color: ${templateBlockStyles?.emailBg}'>
              <div style='${styles} margin: 0 auto;'>${blockContainer}</div>
          </body>
          </html>
  `;
  }
}
