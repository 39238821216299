import Popup from 'UILib/Popup/Popup';
import { ReactComponent as AlertIcon } from 'Assets/icons/alert.svg';
import Button from 'UILib/Button/Button';

import styles from './ConfirmArchivationPopup.module.scss';

interface IProps {
  title?: string;
  description: string;
  onClose: () => void;
  open: boolean;
  onDonePressed: () => void;
}

const ConfirmArchivationPopup = ({
  open,
  title = 'Are You Sure?',
  description,
  onClose,
  onDonePressed,
}: IProps) => {
  return open ? (
    <Popup onClose={onClose} wrapperClassName={styles.container}>
      <div className={styles.confirmationModal}>
        <AlertIcon />
        <h1 className={styles.headerText}>{title}</h1>
        <p className={styles.deleteDomainText}>{description}</p>
        <div className={styles.buttonsBlock}>
          <Button width={180} onClick={onClose} appearance="stroke">
            Cancel
          </Button>
          <Button width={180} onClick={onDonePressed}>
            Delete
          </Button>
        </div>
      </div>
    </Popup>
  ) : null;
};

export default ConfirmArchivationPopup;
