export const UPDATE_AUTO_APP = {
  operationName: 'updateAutoApp',
  query: `mutation updateAutoApp ($id: String!, $name: String, $description: String, $iconUrl: String, $templateId: String) {
                updateAutoApp (id: $id, name: $name, description: $description, iconUrl: $iconUrl, templateId: $templateId) {
                      _id
                      template { _id }
                      name
                      description
                      iconUrl
                      createdAt
                      updatedAt
                }
            }`,
};
