export const GET_USER_AUTO_APPS = {
  operationName: 'getUserAutoApps',
  query: `query getUserAutoApps {
              getUserAutoApps {
                _id 
                template { _id }
                name
                description
                iconUrl
                createdAt
                updatedAt
              }
          }`,
};
