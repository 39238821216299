import { connect } from 'react-redux';
import { ReactComponent as AlertIcon } from 'Assets/icons/alert.svg';
import { deleteDomain, removeDomainPopup } from 'store/domains/domainsActions';
import { RootState } from 'store/rootStore';
import Button from 'UILib/Button/Button';
import Popup from 'UILib/Popup/Popup';

import styles from './RemoveDomainPopup.module.scss';

interface IProps {
  selectedDomain?: string;
  openRemoveDomainPopup: boolean;
  deleteDomain: (id: string) => void;
  toggleRemoveDomainPopup: (payload: boolean) => void;
}

const RemoveDomainPopup = ({
  selectedDomain,
  openRemoveDomainPopup,
  deleteDomain,
  toggleRemoveDomainPopup,
}: IProps) => {
  if (!openRemoveDomainPopup) return null;

  return (
    <Popup
      onClose={() => toggleRemoveDomainPopup(false)}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <AlertIcon width={50} height={50} fill="black" />
        <div className={styles.title}>Are You Sure?</div>
        <div className={styles.subtitle}>
          If you remove the domain, your associated funnel will lose it and
          automatically go to the AutoFunnel.ai subdomain.
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          height={50}
          appearance="stroke"
          onClick={() => toggleRemoveDomainPopup(false)}
        >
          No, keep domain
        </Button>
        <Button
          height={50}
          appearance="highlighted"
          onClick={() => {
            if (selectedDomain) {
              deleteDomain(selectedDomain);
              toggleRemoveDomainPopup(false);
            }
          }}
        >
          Yes, Remove
        </Button>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedDomain: state.domains.selectedDomain,
  openRemoveDomainPopup: state.domains.openRemoveDomainPopup,
});

const mapDispatchToProps = {
  deleteDomain: (id: string) => deleteDomain(id),
  toggleRemoveDomainPopup: (payload: boolean) => removeDomainPopup(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveDomainPopup);
