import { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { Descendant } from 'slate';
import { connect } from 'react-redux';
import { unified } from 'unified';
import markdown from 'remark-parse';
import gfm from 'remark-gfm';
import frontmatter from 'remark-frontmatter';
import { remarkToSlate } from 'remark-slate-transformer';
import { Socket } from 'socket.io-client';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import { IBookleTemplateEditor } from 'store/books/booksReducer';
import domtoimage from 'utils/dom-to-image';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { createSocket, getToken, api } from 'utils/Utils';
import { capitalizeFirstLetter, base64ToFile } from 'utils/helpers';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import GenerationPreview from './GenerationPreview/GenerationPreview';
import TemplateView from './TemplateView/TemplateView';
import { INode } from './Nodes/Node';
import { INodeUserInput } from './Nodes/UserInput';
import { INodeGenerateImage } from './Nodes/GenerateImage';
import { INodeGenerateList } from './Nodes/GenerateList';
import { INodeGenerateText } from './Nodes/GenerateText';
import { PAGECRAFT_API_URL } from '../../Constants';
import { MenuItems } from './Draggable/Sidebar/Sidebar';

import styles from './PageBookleTemplateEditor.module.scss';

interface IProps {
  bookleTemplateBlocks: BookleTemplateBlock[];
  templateTextEditor: IBookleTemplateEditor;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
}

const PageBookleTemplateEditor = ({
  bookleTemplateBlocks,
  templateTextEditor,
  updateBlocks,
  updateTextEditor,
}: IProps) => {
  const socket = useRef<Socket | null>(null);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appId = queryParams.get('app');
  const [app, setApp] = useState<IAutoApp | undefined>(undefined);

  const { templateId } = useParams<{ templateId: string }>();

  const [generationTemplateData, setGenerationTemplateData] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);

  const [nodeViewMode, setNodeViewMode] = useState(false);

  ///////////////////////refactoring area
  const [currentGenTaskId, setCurrentGenTaskId] = useState<string | null>(null);
  const [_tmp_generationData, _tmp_setGenerationData] = useState<any>({});
  const [_tmp_generationTaskId, _tmp_setGenerationTaskId] = useState();
  const [_tmp_nodes, _tmp_setNodes] = useState<any>({});
  /////////////////////////////////////////

  const ref = useRef<HTMLDivElement>(null);
  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as any));

    if (
      ref.current &&
      !ref.current.contains(e.target as any) &&
      !clickedInsideTextEditor
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  useEffect(() => {
    const search = location.search;
    if (search.includes('editor')) {
      setNodeViewMode(true);
    } else {
      setNodeViewMode(false);
    }
  }, [location]);

  useEffect(() => {
    if (appId) {
      graphQlCall({
        queryTemplateObject: queries.GET_ONE_AUTO_APP,
        headerType: 'USER-AUTH',
        values: {
          id: appId,
        },
      }).then((data) => {
        setApp(data);
      });
    }
  }, [appId]);

  const handleRedirectToTemplates = () => {
    history.push(
      app?._id
        ? `/console/app/${app.name.toLowerCase().replace(/\s+/g, '')}/${
            app?._id
          }`
        : `/console/outreach/templates`
    );
  };

  const handleTemplateSave = async () => {
    const actions = generateTemplateFromNodes();
    console.log('SAVING....');

    let valuesToSave: any = {
      id: templateId,
      name: generationTemplateData.name,
    };

    if (Object.keys(actions).length !== 0) {
      valuesToSave['actions'] = JSON.stringify(actions);
    }

    if (bookleTemplateBlocks.length > 0) {
      valuesToSave['layout'] = JSON.stringify(bookleTemplateBlocks);
    }

    await graphQlCall({
      queryTemplateObject: queries.UPDATE_GENERATION_TEMPLATE_MUTATION,
      values: valuesToSave,
      headerType: 'USER-AUTH',
    });

    //saving image
    if (bookleTemplateBlocks) {
      const domNode = document.getElementById('GenerationTemplateLayout');
      if (domNode) {
        const rect = domNode.getBoundingClientRect();
        const dataUrl = await domtoimage.toJpeg(domNode as HTMLElement, {
          height: rect.height,
          width: rect.width,
        });
        const data = new FormData();
        data.append('id', templateId);
        data.append('file', base64ToFile(dataUrl, generationTemplateData.name));
        await api(
          `${PAGECRAFT_API_URL}/generation/upload-thumbnail`,
          'POST',
          data,
          {
            Authorization: getToken(),
          }
        );
      }
    }

    console.log('SAVE is complete');
  };

  useEffect(() => {
    if (!socket.current) {
      socket.current = createSocket();
      socket.current.on(
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );
      socket.current.on('connect', () => {
        console.log('connected to server');

        if (currentGenTaskId) {
          subscribeOnGenerationTaskUpdates(currentGenTaskId);
        }
      });
    }
    loadGenerationTemplateData();

    return () => {
      if (socket.current) {
        socket.current.off(
          'generating-task-info-response',
          handleGenerationTaskUpdates
        );
      }
    };
  }, []);

  useEffect(() => {
    if (socket.current) {
      socket.current.on(
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          'generating-task-info-response',
          handleGenerationTaskUpdates
        );
      }
    };
  }, [bookleTemplateBlocks]);

  useEffect(() => {
    if (currentGenTaskId) {
      subscribeOnGenerationTaskUpdates(currentGenTaskId);
    }
  }, [currentGenTaskId]);

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    if (socket.current) {
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  const handleGenerationTaskUpdates = (payload: any) => {
    console.log('stuff is coming:', payload);
    if (payload.action === 'content generated') {
      if (payload.data) {
        //TODO: need to fix backend and send error outside data
        if (payload.data.error) {
          console.log('errror: ', payload.data.error);
        } else {
          updateGenerationBlockContent(payload.path, payload.data.result);
        }
      }
    }
  };

  const loadGenerationTemplateData = () => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
      values: { id: templateId },
      headerType: 'USER-AUTH',
    })
      .then((data) => {
        setGenerationTemplateData(data);
        updateBlocks(data.layout);
        setLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleNodeExposure = (node: INode) => {
    const newBlocks = [
      ...bookleTemplateBlocks,
      {
        id: node.id,
        type: node.type?.includes('Image')
          ? MenuItems.IMAGE_BLOCK
          : MenuItems.TEXT_BLOCK,
        variable: node.variable,
        nodeId: node.id,
        ...(node.type?.includes('Text') && {
          readOnly: true,
          text: undefined,
        }),
      },
    ];
    updateBlocks(newBlocks);
  };

  const convertNode = (node: any) => {
    switch (node.type) {
      case 'root':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'paragraph':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'text':
        return {
          text: node.value,
        };
      case 'heading':
        return {
          type: 'title',
          // type: `heading-${node.depth}`,
          depth: node.depth,
          children: node.children.map(convertNode),
        };
      // case "emphasis":
      //   return {
      //     type: "emphasis",
      //     children: node.children.map(convertNode),
      //   };
      case 'break':
        return {
          type: 'break',
          children: [{ text: '\n' }],
        };
      case 'thematicBreak':
        return {
          type: 'line',
          children: [{ text: '' }],
        };
      case 'list':
        return {
          type: 'bulleted-list',
          // ordered: node.ordered,
          children: node.children.map(convertNode),
        };
      case 'listItem':
        return {
          type: 'list-item',
          children: node.children.map(convertNode),
        };
      // case "link":
      //   return {
      //     type: "link",
      //     url: node.url,
      //     children: node.children.map(convertNode),
      //   };
      default:
        const finalNode = node;
        if (node.strong) {
          finalNode.bold = true;
        }
        return finalNode;
    }
  };

  const parseMarkdown = (content: string) => {
    const toSlateProcessor = unified()
      .use(markdown)
      .use(gfm)
      .use(frontmatter)
      .use(remarkToSlate);
    const toSlate = (s: string) => toSlateProcessor.processSync(s).result;

    // const processor = unified().use(remarkParse).use(remarkStringify);
    // const tree = processor.parse(content);
    // return tree.children.map(convertNode) as Descendant[];

    return toSlate(content).map(convertNode);
    //   }
  };

  const updateGenerationBlockContent = (path: string, content: string) => {
    const pathComponents = path.split('.');
    const variable = pathComponents[pathComponents.length - 1];

    for (const block of bookleTemplateBlocks) {
      if (block.variable === variable) {
        block.generating = false;
        if (block.type === MenuItems.TEXT_BLOCK) {
          block.text = parseMarkdown(content) as Descendant[];
        } else if (block.type === MenuItems.IMAGE_BLOCK) {
          block.image = content;
        }
        updateBlocks([...bookleTemplateBlocks]);
        break;
      }
    }
  };

  const handleTemplateNameChange = (name: string) => {
    generationTemplateData.name = name;
    setGenerationTemplateData({ ...generationTemplateData });
  };

  const handleNodeVariableChange = (node: INode) => {
    const variableCurrentValue = node.variable; //NOTE: ?? why ??
    bookleTemplateBlocks.forEach((block) => {
      console.log('block:', block);
      if (block.nodeId === node.id) {
        block.variable = variableCurrentValue;
      }
    });
    updateBlocks([...bookleTemplateBlocks]);
  };

  const handleDataChange = (nodes: any) => {
    //TODO: figure out another way of doing this dictionary processing
    let data: any = {};
    for (const key in nodes) {
      const node = nodes[key];
      if (node.type === 'UserInput') {
        const nodeUi = node as INodeUserInput;
        //capture variables
        for (const form of nodeUi.forms) {
          for (const input of form.fields) {
            data[input.id] = input.example;
          }
        }
      }
    }

    _tmp_setNodes(nodes);
    _tmp_setGenerationData(data);
  };

  const generateTemplateFromNodes = () => {
    let actions: any = {};
    const visitedNodes = new Set<string>();

    const serializeNode = (node: INode) => {
      const serializedNode: any = {
        id: node.variable ?? node.id,
        type: node.type,
      };

      if (node.type === 'UserInput') {
        serializedNode.forms = (node as INodeUserInput).forms.map((form) => ({
          name: form.name,
          type: form.name
            .split(' ')
            .map((word) => capitalizeFirstLetter(word))
            .join(''),
          variables: form.fields.map((field) => ({
            id: field.id,
            type: field.type,
            label: field.label,
            options: field.options,
          })),
        }));
      } else if (node.type === 'GenerateText') {
        serializedNode.variable = (node as INodeGenerateText).variable;
        serializedNode.prompt = (node as INodeGenerateText).prompt;
      } else if (node.type === 'GenerateList') {
        serializedNode.variable = (node as INodeGenerateList).variable;
        serializedNode.prompt = (node as INodeGenerateList).prompt;
      } else if (node.type === 'GenerateImage') {
        serializedNode.variable = (node as INodeGenerateImage).variable;
        serializedNode.prompt = (node as INodeGenerateImage).prompt;
      }

      serializedNode.metadata = {
        width: node.width,
        height: node.height,
        x: node.x,
        y: node.y,
      };

      if (node.connections.length > 0) {
        serializedNode.postFunctions = node.connections.map((connectionId) => {
          const connectedNode = _tmp_nodes[connectionId];
          return {
            [connectedNode.operation || 'each']: serializeNode(connectedNode),
          };
        });
      } else {
        serializedNode.postFunctions = [];
      }

      return serializedNode;
    };

    const traverseAndSerialize = (node: INode) => {
      if (visitedNodes.has(node.id)) return null;
      visitedNodes.add(node.id);
      return serializeNode(node);
    };

    for (const key in _tmp_nodes) {
      const node = _tmp_nodes[key];
      //TODO: using UserInput as a root NODE where all connections are processed. need to finalize and clear
      if (node.type !== 'UserInput') {
        continue;
      }
      const serializedNode = traverseAndSerialize(node);
      if (serializedNode) {
        actions = serializedNode;
      }
    }

    return actions;
  };

  const handleGenerationStart = async () => {
    // updateGenerationBlockContent('root.itinerary', _tmp_JSON);
    // return;

    const anyEmptyFields = Object.values(_tmp_generationData).some(
      (el) => el === '' || el === undefined || el === null
    );
    if (anyEmptyFields) {
      notification.error({
        message: 'Please fill in all fields of User Input to see the preview.',
        placement: 'topRight',
        duration: 5,
      });
      return;
    }

    await handleTemplateSave();

    console.log('GENERATION STARTED...');
    //mark all nodes that have variable as pennding for generation
    for (const block of bookleTemplateBlocks) {
      if (block.variable) {
        block.generating = true;
      }
    }
    updateBlocks([...bookleTemplateBlocks]);

    const task = await graphQlCall({
      queryTemplateObject: queries.CREATE_GENERATION_TASK_MUTATION,
      values: { templateId: templateId },
      headerType: 'USER-AUTH',
    });

    console.log('TASK:', task);

    //sett curent gen so it will be subscribed on updates from generation task
    setCurrentGenTaskId(task.task._id);

    const response = await graphQlCall({
      queryTemplateObject: queries.PUSH_DATA_TO_GENERATION_TASK_MUTATION,
      values: {
        taskId: task.task._id,
        data: JSON.stringify(_tmp_generationData),
        path: 'root',
      },
      headerType: 'USER-AUTH',
    });
    console.log('push data response: ', response);
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div ref={ref} id="header">
        <EditHeader
          showConfirmButton={true}
          pageName={generationTemplateData?.name || ''}
          editableTitle
          onTitleChange={handleTemplateNameChange}
          title="Template name"
          handleConfirm={handleTemplateSave}
          handleGoBack={handleRedirectToTemplates}
          buttonPlaceholder="Save"
          additionalButtons={
            nodeViewMode ? (
              <Button
                appearance="stroke"
                width={160}
                height={40}
                onClick={handleGenerationStart}
              >
                Preview
              </Button>
            ) : undefined
          }
          customHeaderPlace="right"
          showCustomHeader={!!templateTextEditor.editor}
          className={styles.header}
          customHeader={
            <TextEditorToolbar
              editor={templateTextEditor.editor}
              selection={templateTextEditor.selection}
              showListItems={false}
            />
          }
        />
      </div>
      <div className={styles.workbench}>
        {nodeViewMode && (
          <>
            <div className={styles.nodeCanvas}>
              <TemplateView
                templateActions={generationTemplateData.actions}
                onVariableChange={handleNodeVariableChange}
                onExpose={handleNodeExposure}
                onChange={handleDataChange}
              />
            </div>
          </>
        )}

        <div className={styles.generationPreview}>
          <GenerationPreview />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
  bookleTemplateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageBookleTemplateEditor);
