export const CREATE_USER_MUTATION = {
  operationName: 'createUser',
  query: `mutation createUser(
            $email: String!, 
            $name: String, 
            $subdomain: String, 
            $stripeClientId: String, 
            $saasId: String, 
            $planId: String,
          ) {
            createUser(
              email: $email
              name: $name
              subdomain: $subdomain,
              stripeClientId: $stripeClientId, 
              saasId: $saasId, 
              planId: $planId,
            ) {
              _id
              name
              owner
              email
              role
            }
          }`,
};
